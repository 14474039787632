<app-header langSelectorSize="sm"></app-header>

<main class="dark:bg-slate-900 flex flex-grow flex-col bg-white px-11.5 pb-18 pt-12 max-md:px-4 max-md:py-5">
  <ng-content *ngIf="!resolverLoading; else loader"></ng-content>
  <ng-template #loader>
    <app-loader />
  </ng-template>
</main>

<app-footer></app-footer>
