import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { RatesService } from "src/app/services/rates.service";
import { SignInModalComponent } from "./modals/sign-in-modal/sign-in-modal.component";
import { SignUpModalComponent } from "./modals/sign-up-modal/sign-up-modal.component";

interface LandingSlide {
  id: number;
  imgSrc: string;
  title: {
    common: string;
    highlighted: string;
  };
}

@Component({
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
})
export class LandingComponent implements OnInit, OnDestroy {
  public slides: LandingSlide[] = [
    {
      id: 0,
      imgSrc: "assets/images/landing/hero-slides/slide-1.png",
      title: { common: "Официальный\u00A0криптообменник", highlighted: "в Узбекистане" },
    },
    {
      id: 1,
      imgSrc: "assets/images/landing/hero-slides/slide-2.png",
      title: { common: "Удобная платформа для", highlighted: "обмена USDT и BTC" },
    },
  ];
  public activeSlideId = this.slides[0].id;
  public rates: RateDto[] = [];

  private slidesIntervalId: NodeJS.Timer | null = null;

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.slidesIntervalId = setInterval(() => {
      const activeSlideIndex = this.slideIds.findIndex(slideId => slideId === this.activeSlideId);
      const nextSlideIndex = activeSlideIndex + 1;
      if (nextSlideIndex === this.slides.length) {
        this.activeSlideId = this.slides[0].id;
        return;
      }
      this.activeSlideId = nextSlideIndex;
    }, 4000);

    await this.getRates();
  }

  public get slideIds() {
    return this.slides.map(slide => slide.id);
  }

  public get slidesTranslateStyle() {
    const activeSlideIndex = this.slideIds.findIndex(slideId => slideId === this.activeSlideId);
    return `translateX(-${activeSlideIndex * 100}vw)`;
  }

  public get supportedTokenRates() {
    return this.rates.filter(rate => rate.symbol !== CryptoSymbol.Uzs).slice(0, 5);
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    this.rates = res.params || [];
  }

  public openSignInModal() {
    this._modalService.open(SignInModalComponent);
  }

  public openSignUpModal() {
    this._modalService.open(SignUpModalComponent);
  }

  public handleSelectSlide(slideId: number) {
    this.activeSlideId = slideId;
  }

  ngOnDestroy() {
    if (this.slidesIntervalId) {
      clearInterval(this.slidesIntervalId);
    }
  }
}
