export class BitcoinRawTxDto {
  txid = "";
  version = 2;
  locktime = 0;
  vin = [
    {
      txid: "",
      vout: 1,
      prevout: {
        scriptpubkey: "",
        scriptpubkey_asm: "",
        scriptpubkey_type: "",
        scriptpubkey_address: "",
        value: 0,
      },
      scriptsig: "",
      scriptsig_asm: "",
      witness: [""],
      is_coinbase: false,
      sequence: 0,
    },
  ];
  vout = [
    {
      scriptpubkey: "",
      scriptpubkey_asm: "",
      scriptpubkey_type: "",
      scriptpubkey_address: "",
      value: 0,
    },
  ];
  size = 0;
  weight = 0;
  sigops = 1;
  fee = 0;
  status = {
    confirmed: true,
    block_height: 0,
    block_hash: "",
    block_time: 0,
  };
}
