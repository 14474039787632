<div
  class="grow rounded-2xl border border-gray-100/30 bg-blue-50 px-9 py-6 dark:border-slate-700 dark:bg-slate-800 max-md:rounded-none max-md:border-none max-md:bg-transparent max-md:p-0 dark:max-md:bg-transparent"
>
  <div class="mb-4 flex h-20 items-end justify-between">
    <div class="dropdown" ngbDropdown container="body" display="dynamic" placement="bottom-start top-start">
      <div id="custom-dropdown" ngbDropdownToggle class="dropdown__toggle">
        <div class="flex items-center gap-x-2 py-3 text-lg font-semibold">
          {{ tokenSwitchValues[currentSwitchNumber] }}
          <app-svg-icon name="chevron" class="block w-3 text-black dark:text-white" />
        </div>
      </div>

      <ul aria-labelledby="custom-dropdown" ngbDropdownMenu class="dropdown__list">
        <li
          *ngFor="let item of tokenSwitchValues; let index = index"
          class="dropdown__item"
          (click)="switchTxType(index)"
          ngbDropdownItem
        >
          <span class="text-xs text-slate-400 dark:text-slate-200">{{ item }}</span>
        </li>
      </ul>
    </div>

    <!-- Desktop filters -->
    <div *ngIf="shouldRenderDateFilters" class="hidden gap-x-8 md:flex md:items-end md:justify-end">
      <button
        class="btn-outline-danger border-0 py-3 max-md:order-last"
        [disabled]="isLoading"
        (click)="resetDates()"
      >
        Cбросить
      </button>

      <div class="flex gap-x-2 max-md:flex-col md:items-center">
        <div #startDateInputContainer class="with-dropdown-calendar relative">
          <span class="mb-2 block font-medium text-slate-400">Дата с</span>
          <div class="flex items-center rounded-2xl border border-slate-200 bg-white px-4 py-2.5">
            <input
              type="text"
              ngbDatepicker
              #startDatePicker="ngbDatepicker"
              placeholder="01.01.2025"
              [(ngModel)]="startDate"
              [positionTarget]="startDateInputContainer"
              navigation="none"
              [displayMonths]="1"
              [contentTemplate]="calendar"
              [dayTemplate]="calendarDay"
              outsideDays="visible"
              [weekdays]="false"
              [disabled]="isLoading"
              datepickerClass="ngb-datepicker"
              class="flex-1 bg-transparent text-slate-400 focus-visible:outline-none"
              (ngModelChange)="startDatePicker.navigateTo(startDate)"
              (change)="handleDateFilterChange()"
              (dateSelect)="handleDateFilterChange()"
            />
            <button (click)="startDatePicker.toggle()">
              <app-svg-icon name="calendar" class="h-6 w-6 text-blue-950" />
            </button>
          </div>
        </div>

        <div #endDateInputContainer class="relative">
          <span class="mb-2 block font-medium text-slate-400">Дата до</span>
          <div class="flex items-center rounded-2xl border border-slate-200 bg-white px-4 py-2.5">
            <input
              type="text"
              ngbDatepicker
              #endDatePicker="ngbDatepicker"
              placeholder="01.01.2025"
              [(ngModel)]="endDate"
              [minDate]="minEndDate"
              [positionTarget]="endDateInputContainer"
              navigation="none"
              [displayMonths]="1"
              [contentTemplate]="calendar"
              [dayTemplate]="calendarDay"
              outsideDays="visible"
              [weekdays]="false"
              [disabled]="isLoading"
              datepickerClass="ngb-datepicker"
              class="flex-1 bg-transparent text-slate-400 focus-visible:outline-none"
              (ngModelChange)="endDatePicker.navigateTo(endDate)"
              (change)="handleDateFilterChange()"
              (dateSelect)="handleDateFilterChange()"
            />
            <button (click)="endDatePicker.toggle()">
              <app-svg-icon name="calendar" class="h-6 w-6 text-blue-950" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile filters -->
  <div class="flex items-center justify-between md:hidden">
    <button (click)="openModal(mobileTokensModal)" class="flex items-center gap-x-2 text-lg font-semibold">
      {{ tokenSwitchValues[currentSwitchNumber] }}
      <app-svg-icon name="chevron" class="block w-3 text-black dark:text-white" />
    </button>
    <button (click)="openModal(mobileDatePicker)">
      <app-svg-icon name="calendar" class="block w-6 text-slate-400 dark:text-slate-200" />
    </button>
  </div>

  <section
    class="mt-6 max-md:mt-4 max-md:rounded-2xl max-md:border max-md:border-gray-100/30 max-md:bg-blue-50 max-md:px-4 max-md:pb-6 max-md:pt-4 dark:max-md:border-slate-800 dark:max-md:bg-slate-800"
  >
    <div
      class="mb-3 grid grid-cols-[15%_15%_20%_auto] gap-x-6 rounded-xl bg-red-50 px-6 py-3 text-sm font-medium text-slate-400 dark:bg-slate-900 dark:text-white max-md:hidden"
    >
      <span>Тип</span>
      <span>Сумма</span>
      <span>ID</span>
      <span>Дата</span>
    </div>

    <ng-container *ngIf="!isLoading; else txSkeleton">
      <ng-container *ngIf="hasTxs; else noTrans">
        <div class="grid grid-cols-1">
          <app-transaction-list-item
            *ngFor="let item of txs"
            [txDto]="item"
            class="border-t border-gray-100 first:border-none dark:border-slate-700"
          />
        </div>

        <div class="flex items-center justify-end gap-x-5 max-md:mt-6 max-md:justify-center">
          <button
            class="btn-flat max-md:flex-grow"
            (click)="onPrevPage()"
            [disabled]="!hasPrevPage || isLoading"
          >
            <app-svg-icon name="arrow-up" class="block aspect-square w-10 rotate-[-135deg]" />
          </button>
          <button
            class="btn-flat max-md:flex-grow"
            (click)="onNextPage()"
            [disabled]="!hasNextPage || isLoading"
          >
            <app-svg-icon name="arrow-up" class="block aspect-square w-10 rotate-45" />
          </button>
        </div>
      </ng-container>

      <ng-template #noTrans>
        <div class="text-2xl font-bold text-select-bg">
          {{ "Transaction.No_transactions" | translate }}
        </div>
      </ng-template>
    </ng-container>

    <ng-template #txSkeleton>
      <div class="grid grid-rows-1 gap-5 max-md:gap-3">
        <app-transaction-list-item-skeleton />
        <app-transaction-list-item-skeleton class="border-t border-gray-100 dark:border-slate-700" />
      </div>
    </ng-template>
  </section>
</div>

<ng-template #mobileTokensModal let-modal>
  <button class="absolute right-4 top-3" (click)="modal.close()">
    <app-svg-icon name="actions/close" class="block h-6 w-6" />
  </button>
  <div class="flex flex-col items-start gap-y-3">
    <button
      *ngFor="let token of tokenSwitchValues; let i = index"
      (click)="switchTxType(i); modal.close()"
      class="px-2 py-2 text-base font-medium uppercase"
    >
      {{ token }}
    </button>
  </div>
</ng-template>

<ng-template #mobileDatePicker let-modal>
  <section class="relative p-2">
    <button class="absolute right-0 top-0" (click)="modal.close()">
      <app-svg-icon name="actions/close" class="block h-6 w-6" />
    </button>

    <div class="grid grid-cols-2 gap-x-2.5">
      <label>
        <span
          class="mb-1 block font-medium"
          [ngClass]="{ 'text-green-400': currentMobileCalendar === 'start' }"
        >
          С
        </span>
        <input
          type="text"
          name="startDate"
          ngbDatepicker
          [(ngModel)]="startDate"
          (ngModelChange)="mobileStartDatePicker.navigateTo(startDate)"
          (click)="currentMobileCalendar = 'start'"
          class="w-fill rounded-2xl border bg-transparent px-4 py-2 focus:outline-none"
          [ngClass]="
            currentMobileCalendar === 'start'
              ? 'border-green-400 text-green-400'
              : 'border-slate-200 text-slate-400'
          "
          placeholder="01.01.2025"
          autocomplete="off"
        />
      </label>
      <label>
        <span
          class="mb-1 block font-medium"
          [ngClass]="{ 'text-green-400': currentMobileCalendar === 'end' }"
        >
          По
        </span>
        <input
          type="text"
          name="endDate"
          ngbDatepicker
          [(ngModel)]="endDate"
          (ngModelChange)="mobileEndDatePicker.navigateTo(endDate)"
          (click)="currentMobileCalendar = 'end'"
          [startDate]="endDate!"
          class="w-fill rounded-2xl border bg-transparent px-4 py-2 focus-visible:outline-none"
          [ngClass]="
            currentMobileCalendar === 'end'
              ? 'border-green-400 text-green-400 '
              : 'border-slate-200 text-slate-400'
          "
          placeholder="31.12.2025"
          autocomplete="off"
        />
      </label>
    </div>

    <div class="my-5">
      <ngb-datepicker
        #mobileStartDatePicker
        [(ngModel)]="startDate"
        navigation="none"
        [displayMonths]="1"
        [contentTemplate]="calendar"
        [dayTemplate]="calendarDay"
        outsideDays="visible"
        [weekdays]="false"
        class="ngb-datepicker mx-auto block w-fit"
        [ngClass]="{ hidden: currentMobileCalendar !== 'start' }"
      />
      <ngb-datepicker
        #mobileEndDatePicker
        [(ngModel)]="endDate"
        navigation="none"
        [displayMonths]="1"
        [contentTemplate]="calendar"
        [dayTemplate]="calendarDay"
        outsideDays="visible"
        [weekdays]="false"
        class="ngb-datepicker mx-auto block w-fit"
        [ngClass]="{ hidden: currentMobileCalendar !== 'end' }"
      />
    </div>

    <div class="grid grid-cols-2 gap-x-4">
      <button (click)="closeModalAndResetDates(modal)" class="btn-outline-danger btn-medium">Сбросить</button>
      <button (click)="closeModalAndApplyDates(modal)" class="btn-primary btn-medium">Применить</button>
    </div>
  </section>
</ng-template>

<ng-template #calendar let-dp>
  <div class="calendar w-max rounded-2xl border border-gray-100 bg-white p-6 shadow shadow-black/[0.07]">
    <div class="mb-5 flex items-center justify-between text-sm">
      <span class="text-gray-700">Дата</span>
      <div class="flex items-center gap-x-3">
        <button (click)="navigateToDate(dp, -1)">
          <app-svg-icon name="actions/calendar-slide-left" class="h-4 w-4" />
        </button>
        <span class="w-24 text-green-400">
          {{ dp.i18n.getMonthShortName(dp.state.months[0].month) }} {{ dp.state.months[0].year }}
        </span>
        <button class="rotate-180" (click)="navigateToDate(dp, 1)">
          <app-svg-icon name="actions/calendar-slide-left" class="h-4 w-4" />
        </button>
      </div>
    </div>

    <div class="bg-slate-50 mb-2 h-px"></div>

    <div class="mb-2 flex gap-x-3">
      <div *ngFor="let weekday of weekdays" class="flex-1 p-1 text-center">
        <span class="text-slate-300 text-xxs uppercase">{{ weekday }}</span>
      </div>
    </div>

    <ngb-datepicker-month [month]="dp.state.months[0]" class="calendar-month" />
  </div>
</ng-template>

<ng-template
  #calendarDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <div
    class="calendar-day"
    [class.focused]="focused"
    [class.selected]="selected"
    [class.opacity-30]="date.month !== currentMonth"
    [class.text-muted]="disabled"
  >
    <span class="calendar-day-text">{{ date.day }}</span>
  </div>
</ng-template>
