<div class="space-y-2 dark:rounded-2xl dark:bg-black dark:px-3 dark:py-2">
  <h2>Пароль должен содержать:</h2>

  <ul class="list-none space-y-2">
    <li
      *ngFor="let r of passwordRequirements"
      class="flex items-center gap-x-1"
      [ngClass]="
        getIsPasswordReqValid(r.pattern)
          ? 'text-black/50 line-through dark:text-slate-200'
          : 'text-red-400/50'
      "
    >
      <svg
        *ngIf="getIsPasswordReqValid(r.pattern)"
        class="h-6 w-6"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2225 15.7237L6.66699 12.1682L7.91144 10.9237L10.2225 13.2348L16.0892 7.36816L17.3337 8.61261L10.2225 15.7237Z"
          fill="currentColor"
          fill-opacity="0.3"
        />
      </svg>

      <div *ngIf="!getIsPasswordReqValid(r.pattern)" class="flex h-6 w-6 items-center justify-center">
        <div class="h-1 w-1 bg-red-400/50"></div>
      </div>

      <span>{{ r.text | translate }}</span>
    </li>
  </ul>
</div>
