import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { Network } from "src/app/common/enums/network.enum";
import { getNetworkName } from "src/app/common/utils/network-name-helper";

@Component({
  selector: "app-network-dropdown",
  templateUrl: "./network-dropdown.component.html",
  styleUrls: ["./network-dropdown.component.css"],
})
export class NetworkDropdownComponent {
  @HostListener("window:click", ["$event"])
  handleClick(event: KeyboardEvent) {
    this.hideOnClick(event);
  }

  @Input() items: Network[] = [];
  @Input() disabled = false;
  @Input() isFullWidthList = false;

  @Output() onSelect = new EventEmitter<Network>();

  isOpen = false;

  public getNetworkName = getNetworkName;

  toggleSelect() {
    this.isOpen = !this.isOpen;
    console.log("is open:", this.isOpen);
  }

  pickItem(item: Network) {
    if (!this.disabled) {
      this.onSelect.emit(item);
    }
  }

  hideOnClick(e: any) {
    e.stopPropagation();
    if (!e.target.classList.contains("dropdown__list") && e.target.parentNode?.nodeName !== "BUTTON") {
      this.isOpen = false;
    }
  }
}
