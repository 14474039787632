<div
  class="grow rounded-2xl border border-gray-100/30 bg-blue-50 px-9 py-6 dark:border-slate-800 dark:bg-slate-800 max-md:p-4"
>
  <div class="flex items-center justify-between">
    <h2 class="title max-md:text-lg max-md:text-slate-400">{{ "Bank.Title" | translate }}</h2>
    <ng-container *ngIf="canAddCard">
      <button
        class="btn-primary btn-medium max-md:border-0 max-md:!bg-transparent max-md:p-0 max-md:text-sm max-md:text-accent"
        (click)="openAddBankCardModal()"
      >
        Добавить карту
        <app-svg-icon name="add-circle" class="ml-3 h-6 w-6 max-md:hidden dark:max-md:hidden" />
      </button>
    </ng-container>
  </div>

  <section
    *ngIf="cardList.length > 0; else noCardsTemplate"
    class="mt-6 grid grid-cols-4 gap-6 max-md:mt-4 max-md:grid-cols-1 max-md:gap-4"
  >
    <app-bank-card
      *ngFor="let card of cardList; let i = index"
      [card]="card"
      [index]="i"
      [showActions]="true"
      (onDelete)="openDeleteCardModal(card.id)"
      class="[&>div]:h-full"
    />
  </section>

  <ng-template #noCardsTemplate>
    <div class="mt-6 flex flex-col items-center justify-center space-y-6 max-md:mt-2 max-md:items-start">
      <p class="text-slate-400">У вас еще не добавлены карты</p>
      <button
        class="btn-primary btn-medium max-md:w-full"
        (click)="openAddBankCardModal()"
        *ngIf="verificationStatus === VerificationStatus.Verified; else notVerifiedAddCard"
      >
        Добавить
        <app-svg-icon name="add-circle" class="ml-3 h-6 w-6" />
      </button>
      <ng-template #notVerifiedAddCard>
        <div
          class="btn-primary btn-medium cursor-default grayscale"
          [ngbTooltip]="'Profile.Add_card_verification_required' | translate"
          tooltipClass="custom-tooltip bg-text-pale"
        >
          Добавить
          <app-svg-icon name="add-circle" class="ml-3 h-6 w-6" />
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
