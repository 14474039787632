<button (click)="onClose()" class="absolute right-4 top-3 !m-0 md:right-2.5 md:top-2.5">
  <img src="assets/icons/actions/close.svg" alt="close" class="h-8 w-8" />
</button>

<div class="flex flex-col gap-y-4">
  <div class="w-max">
    <app-theme-selector />
  </div>

  <button *ngIf="isTelegramMiniApp" class="space-y-0.5" (click)="handleTelegramWalletConnectClick()">
    <div class="flex items-center gap-x-2">
      <span class="font-medium">Telegram кошелек</span>
      <img src="assets/icons/telegram-wallet.jpg" alt="" class="h-6 w-6 rounded-md" />
    </div>

    <div class="flex items-center gap-x-1">
      <span class="text-sm font-medium" [ngClass]="{ 'text-green-400': !isTelegramWalletConnected }">
        {{ isTelegramWalletConnected ? "Подключен" : "Подключить" }}
      </span>
      <img *ngIf="isTelegramWalletConnected" src="assets/icons/actions/edit.svg" alt="" class="h-4 w-4" />
    </div>
  </button>

  <a routerLink="/depositary/cards" class="flex items-center gap-x-2">
    <span class="font-medium text-slate-400">Карты</span>
    <app-svg-icon name="card" class="h-6 w-6 text-black" />
  </a>

  <a *ngFor="let item of menuItems" [routerLink]="item.link" class="py-2 font-medium">
    {{ item.title | translate }}
  </a>

  <button
    class="flex w-full items-center gap-x-1 border-t border-gray-50 py-2 text-red-500"
    (click)="handleLogoutClick()"
  >
    <span>Выйти</span>
    <img src="assets/icons/actions/logout.svg" alt="" class="h-6 w-6" />
  </button>
</div>
