<div class="bg-landing bg-cover bg-top bg-no-repeat text-blue-50">
  <app-header [transparent]="true" [withAuthState]="false" />

  <main>
    <section class="px-4 pt-6 md:py-9">
      <app-carousel-indicator
        [slideIds]="slideIds"
        [activeSlideId]="activeSlideId"
        class="flex justify-center gap-x-2"
        (select)="handleSelectSlide($event)"
      />

      <div class="overflow-hidden">
        <div
          class="flex w-max gap-x-8 transition-transform duration-300 md:gap-x-32 md:px-16"
          [style.transform]="slidesTranslateStyle"
        >
          <app-carousel-slide
            #carouselSlide
            *ngFor="let slide of slides"
            [slide]="slide"
            (buttonClick)="openSignUpModal()"
          />
        </div>
      </div>
      <button class="btn-primary btn-medium w-full md:hidden" (click)="openSignUpModal()">Регистрация</button>
    </section>

    <section
      class="flex flex-col gap-y-6 pb-7 pt-18 md:flex-row md:justify-center md:gap-x-12 md:gap-y-0 md:py-9"
    >
      <span class="px-4 text-lg font-medium md:px-0 md:font-semibold">
        Поддерживаемые <br class="hidden md:inline" />криптовалюты
      </span>
      <div class="w-auto">
        <div class="flex items-center gap-x-9 overflow-x-scroll px-4 pb-2 md:gap-x-16 md:overflow-x-auto">
          <ng-container *ngFor="let rate of supportedTokenRates">
            <app-token-growth [tokenRate]="rate" />
          </ng-container>
        </div>
      </div>
    </section>

    <section class="px-4 py-2.5 md:-mt-5 md:flex md:justify-center">
      <div class="flex flex-col gap-y-4 rounded-[1.875rem] bg-red-50/10 p-6 md:gap-y-6 md:px-23 md:py-18">
        <!-- Desktop -->
        <div class="hidden flex-col gap-y-4 text-center font-semibold md:flex">
          <h2 class="text-3xl">Меняйте легально</h2>
          <p class="text-xl">Paynet Crypto - компания с легальной крипто-лицензией на внебиржевой обмен</p>
        </div>

        <!-- Mobile -->
        <h2 class="text-lg font-semibold md:hidden">Меняйте по лучшему курсу*</h2>

        <div class="flex flex-col gap-4 md:flex-row md:items-center">
          <app-currency-calculator />
          <button class="btn-primary btn-medium w-full md:w-72 md:flex-1" (click)="openSignInModal()">
            Обменять
          </button>
        </div>

        <span class="text-red-10/60">
          *Курс может меняться в зависимости от комиссии выбранной сети и других факторов.
        </span>
      </div>
    </section>

    <section class="py-18">
      <div class="mx-auto flex flex-col gap-y-6 md:gap-y-18">
        <div class="flex flex-col items-start gap-y-4 px-4 md:items-center">
          <h2 class="text-3xl font-semibold">Как это работает</h2>
          <p>Всё просто, вы точно справитесь</p>
        </div>

        <div class="w-screen md:w-auto">
          <div class="flex items-start gap-x-5 overflow-x-auto px-4 max-md:pb-2 md:justify-center md:gap-x-8">
            <div>
              <div
                class="mb-5 flex h-60 w-60 items-center justify-center rounded-[1.875rem] bg-red-25/10 md:mx-14 md:mb-8"
              >
                <img src="assets/images/landing/instructions/step-1.png" alt="" class="w-24" />
              </div>
              <div class="space-y-3 md:text-center">
                <h3 class="text-2xl font-semibold">1. Регистрация</h3>
                <p class="md:whitespace-pre-line">{{ "Пройдите быструю регистрацию\nс помощью смс" }}</p>
              </div>
            </div>

            <div>
              <div
                class="mb-5 flex h-60 w-60 items-center justify-center rounded-[1.875rem] bg-red-25/10 md:mx-14 md:mb-8"
              >
                <img src="assets/images/landing/instructions/step-2.png" alt="" class="w-44" />
              </div>
              <div class="space-y-3 md:text-center">
                <h3 class="text-2xl font-semibold">2. Идентификация</h3>
                <p class="md:whitespace-pre-line">
                  {{
                    "Для легальной и безопасной работы сервиса\nвам необходимо пройти идентификацию с\nпомощью MyID"
                  }}
                </p>
              </div>
            </div>

            <div>
              <div
                class="mb-5 flex h-60 w-60 items-center justify-center rounded-[1.875rem] bg-red-25/10 md:mx-14 md:mb-8"
              >
                <img src="assets/images/landing/instructions/step-3.png" alt="" class="w-32" />
              </div>
              <div class="space-y-3 md:text-center">
                <h3 class="text-2xl font-semibold">3. Готово</h3>
                <p class="md:whitespace-pre-line">{{ "Вы готовы для погружения в мир\nкрипто-активов" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="px-4 pb-18 md:px-40">
      <h2 class="mb-9 text-center text-2xl font-semibold md:mb-18">Часто задаваемые вопросы</h2>
      <app-faq-accordion />
    </section>
  </main>

  <app-footer [isLarge]="true" />
</div>
