import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IMenuItem } from "src/app/common/constants/menu-items.constant";
import { TonConnectService } from "src/app/services/ton-connect.service";

@Component({
  selector: "app-mobile-menu-modal",
  templateUrl: "./mobile-menu-modal.component.html",
  styleUrls: ["./mobile-menu-modal.component.css"],
})
export class MobileMenuModalComponent {
  @Input() menuItems: IMenuItem[] = [];
  @Input() isTelegramMiniApp = false;
  @Input() isTelegramWalletConnected = false;

  @Output() logoutClick = new EventEmitter<void>();

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _tonConnectService: TonConnectService
  ) {}

  onClose() {
    this._activeModal.close();
  }

  handleTelegramWalletConnectClick() {
    this._tonConnectService.openTelegramWallet();
  }

  handleLogoutClick() {
    this.logoutClick.emit();
  }
}
