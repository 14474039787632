import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AddCardDto } from "src/app/common/DTO/cards/add-card.dto";
import { Constants } from "src/app/common/constants/constants";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CardErrorCode } from "src/app/common/enums/card-error-code.enum";
import { monthYearDateStrValidator } from "src/app/common/validators/month-year-date-str.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validOtpValidator } from "src/app/common/validators/valid-otp.validator";
import { CardService } from "src/app/services/card.service";

enum Step {
  Input = 1,
  Otp = 2,
  Success = 3,
}

@Component({
  selector: "app-add-bank-card-modal",
  templateUrl: "./add-bank-card-modal.component.html",
  styleUrls: ["./add-bank-card-modal.component.css"],
})
export class AddBankCardModalComponent {
  @Input() userPhoneNumber: string = "";

  step: Step = Step.Input;
  businessError: string | null = null;
  otpInputCount: number = 0;
  otpTimer: number = 0;
  validateConstants = ValidateConstants;
  isPending: boolean = false;

  addCardForm: FormGroup;
  addCardOtpForm: FormGroup;

  private addedCardId: number = 0;

  @Output() onAddCard = new EventEmitter();
  @Output() onCardAdded = new EventEmitter();

  constructor(
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _modalRef: NgbActiveModal
  ) {
    this.addCardForm = new FormGroup({
      number: new FormControl(null, [
        Validators.required,
        Validators.minLength(ValidateConstants.CardNumberLength),
        Validators.maxLength(ValidateConstants.CardNumberLength),
        numericStrValidator(),
      ]),
      expiryAt: new FormControl(null, [Validators.required, monthYearDateStrValidator(true)]),
    });

    this.addCardOtpForm = new FormGroup({
      cardVerificationCode: new FormControl("", [
        Validators.required,
        numericStrValidator(),
        validOtpValidator(),
      ]),
    });
  }

  onBack() {
    this.step = Step.Input;
    this.businessError = null;
  }

  async onCardSubmit() {
    if (this.addCardForm.invalid) {
      return;
    }

    this.isPending = true;
    const dto: AddCardDto = {
      number: this.addCardForm.get("number")!.value,
      expiryAt: this.convertMonthYearStrToDate(this.addCardForm.get("expiryAt")!.value),
      language: this.language,
    };
    const res = await this._cardService.addCard(dto);
    if (res.withError) {
      this.errorParser(res.errorCode);
    } else {
      this.addedCardId = res.params?.cardId!;
      this.step = Step.Otp;
      this.otpTimer = Constants.ConfirmCodeLifeTimeInSec;
      this.businessError = null;
      this.onAddCard.emit();
    }
    this.isPending = false;
  }

  async onCodeSubmit() {
    if (this.addCardOtpForm.invalid) {
      return;
    }
    if (this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessError = this._translateService.instant("Login.Pass_captcha");
      return;
    }

    this.businessError = null;
    this.isPending = true;
    const cardVerificationCode = this.addCardOtpForm.get("cardVerificationCode")?.value;
    const res = await this._cardService.verifyCard(this.addedCardId, cardVerificationCode);

    if (res.withError) {
      this.errorParser(res.errorCode);
    } else {
      this.onCardAdded.emit();
      this.step = Step.Success;
    }
    this.isPending = false;
  }

  async sendCodeToPhone() {
    this.businessError = null;

    const res = await this._cardService.requestVerificationCode(this.addedCardId, this.language);
    if (res.withError) {
      this.errorParser(res.errorCode);
    } else {
      this.otpTimer = Constants.ConfirmCodeLifeTimeInSec;
    }
  }

  public captchaResolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  public onClose() {
    this._modalRef.close();
  }

  public get getNumberErrMsg(): string | null {
    const number = this.addCardForm.get("number");

    if (number == null || number.value == null) {
      return null;
    }

    if (number.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (number.hasError("minlength") || number.hasError("maxlength")) {
      return this._translateService.instant("Bank.Number_incorrect");
    }

    if (number.hasError("numericStr")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public get getExpiryAtErrMsg(): string | null {
    const expiryAt = this.addCardForm.get("expiryAt");
    if (expiryAt == null || expiryAt.value == null) {
      return null;
    }

    if (expiryAt.hasError("dateInPast")) {
      return this._translateService.instant("Bank.Card_expired");
    }

    if (expiryAt.invalid) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public get getCodeErrMsg(): string | null {
    const cardVerificationCode = this.addCardOtpForm.get("cardVerificationCode");

    if (
      cardVerificationCode == null ||
      cardVerificationCode.value == null ||
      cardVerificationCode.touched == false
    ) {
      return null;
    }

    if (cardVerificationCode.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (cardVerificationCode.hasError("otpIsIncorrect")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public get maskedPhoneNumber(): string {
    const visibleDigits = 2;
    return this.userPhoneNumber.slice(-visibleDigits).padStart(this.userPhoneNumber.length, "*");
  }

  private convertMonthYearStrToDate(str: string): Date {
    const tmp = str.split("/");
    const year = `20${tmp[1]}`;
    return new Date(+year, +tmp[0]);
  }

  private get language() {
    const lang = this._translateService.currentLang as LanguageConstants;
    if (!lang) {
      return 0;
    }
    switch (lang) {
      case LanguageConstants.UZBEK:
        return 0;
      case LanguageConstants.RUSSIAN:
        return 1;
      default:
        return 0;
    }
  }

  private readonly errorParser = (errorCode: CardErrorCode | null): void => {
    switch (errorCode) {
      case CardErrorCode.ExceededCardCountLimit:
        this.businessError = this._translateService.instant("Bank.Exceeded_limit");
        break;
      case CardErrorCode.CardAlreadyExists:
        this.businessError = this._translateService.instant("Bank.Card_already_added");
        break;
      case CardErrorCode.DisallowSmsSent:
        this.businessError = this._translateService.instant("Login.Disallow_sms_sent");
        break;
      case CardErrorCode.ServerAbordConnection:
        this.businessError = this._translateService.instant("Common.Server_abord_connection");
        break;
      case CardErrorCode.IncorrectCardData:
        this.businessError = this._translateService.instant("Bank.Incorrect_card_data");
        break;
      case CardErrorCode.BadRequest:
        this.businessError = this._translateService.instant("Common.Bad_request");
        break;
      case CardErrorCode.IncorrectCode:
        this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
        break;
      case CardErrorCode.CodeRequestLimitExceeds:
        this.businessError = this._translateService.instant("Login.Code_request_limit_exceeds");
        break;
      case CardErrorCode.OTPPhoneNumberDifferent:
        this.businessError = this._translateService.instant("Bank.OTP_phone_number_different");
        break;
      case CardErrorCode.IncorrectPhoneNumber:
        this.businessError = this._translateService.instant("Bank.Incorrect_phone_number");
        break;
      default:
        this.businessError = this._translateService.instant("Common.Unknown_error");
    }
  };
}
