<h2 class="mb-6 text-2xl font-medium">
  {{ "Password_change.Title" | translate }}
</h2>

<div *ngIf="isSuccess" class="mb-4 flex items-center gap-x-2 rounded-xl bg-text-pale p-2">
  <app-svg-icon name="settings/success" class="h-8 w-8" />
  <p class="text-sm text-white">
    {{ "Password_change.Password_changed" | translate }}
  </p>
</div>

<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" class="flex flex-col gap-y-4">
  <app-input
    type="password"
    [title]="'Password_change.Old_password' | translate"
    [placeholder]="'Password_change.Input_placeholder' | translate"
    formControlName="oldPsw"
    id="oldPsw"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('oldPsw')"
    [withPasswordToggle]="true"
  />
  <app-input
    type="text"
    [title]="'Password_change.New_password' | translate"
    [placeholder]="'Password_change.Input_placeholder' | translate"
    formControlName="newPsw"
    id="newPsw"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('newPsw')"
  />
  <app-password-checker
    *ngIf="getFieldHasErrors('newPsw')"
    [password]="changePasswordForm.get('newPsw')?.value"
    [confirmPassword]="changePasswordForm.get('pswRepeat')?.value"
  />

  <app-input
    type="text"
    [title]="'Password_change.Repeat_new_password' | translate"
    [placeholder]="'Password_change.Input_placeholder' | translate"
    formControlName="pswRepeat"
    id="pswRepeat"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('pswRepeat')"
  />

  <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

  <button
    type="submit"
    class="btn-primary btn-medium mt-2 w-full"
    [disabled]="changePasswordForm.invalid || isPending"
  >
    {{ "Common.Continue" | translate }}
  </button>
</form>
