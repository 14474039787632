<button (click)="onClose()" class="absolute right-2 top-2 max-md:right-0 max-md:top-0">
  <app-svg-icon name="actions/close" class="block h-8 w-8 text-black dark:text-white" />
</button>

<button
  *ngIf="step === 'password'"
  class="absolute left-0 top-0 rounded-md bg-gray-25 dark:bg-slate-800 md:left-2.5 md:top-2.5"
  (click)="onBack()"
>
  <app-svg-icon name="actions/back" class="block h-8 w-8 text-black dark:text-white" />
</button>

<div class="px-15 py-7 max-md:p-0">
  <ng-container [ngSwitch]="step">
    <!-- Enter phone -->
    <ng-template ngSwitchCase="phone">
      <h2 class="title text-center">{{ "Login.Password_reset" | translate }}</h2>
      <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="mt-6">
        <div class="rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            [title]="'Login.Phone_number' | translate"
            type="phone"
            placeholder="11 222 33 44"
            id="phoneNumber"
            formControlName="phoneNumber"
            [errorMsg]="phoneNumberError"
            mask="00 000 00 00"
            [autocomplete]="'off'"
            class="mt-6"
          />
        </div>
        <p *ngIf="businessError" class="mt-2 text-sm font-medium text-danger">{{ businessError }}</p>
        <button
          type="submit"
          class="btn-primary btn-medium mt-6 w-full"
          [disabled]="phoneForm.invalid || isPending"
        >
          {{ "Common.Next" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Set new password and enter code -->
    <ng-template ngSwitchCase="password">
      <h2 class="title text-center">{{ "Login.Password_reset" | translate }}</h2>
      <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()" class="mt-6 flex flex-col space-y-6">
        <div class="flex flex-col gap-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            type="password"
            [title]="'Profile.New_password' | translate"
            placeholder="********"
            formControlName="newPsw"
            id="newPsw"
            autocomplete="off"
            [withPasswordToggle]="true"
          />
          <app-input
            type="password"
            [title]="'Profile.Repeat_new_password' | translate"
            placeholder="********"
            formControlName="pswRepeat"
            id="pswRepeat"
            autocomplete="off"
            [withPasswordToggle]="true"
          />
          <app-input
            type="text"
            [title]="'Login.Sms_code' | translate"
            placeholder="------"
            formControlName="code"
            id="code"
            [timer]="otpTimer"
            (onRefresh)="sendCode()"
            autocomplete="off"
          />
          <app-password-checker
            [password]="passwordForm.get('newPsw')?.value"
            [confirmPassword]="passwordForm.get('pswRepeat')?.value"
          />

          <re-captcha
            *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
            (resolved)="captchaResolved($event)"
          ></re-captcha>
        </div>

        <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

        <button
          type="submit"
          class="btn-primary btn-medium mt-6 w-full"
          [disabled]="passwordForm.invalid || isPending || otpInputCount >= validateConstants.MaxOtpTries"
        >
          {{ "Login.Change_password" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Success -->
    <ng-template ngSwitchCase="success">
      <h2 class="title text-center">
        {{ "Password_change.Password_changed" | translate }}
      </h2>
      <button class="btn-primary-dark btn-medium mt-6 w-full" (click)="onClose()">
        {{ "Common.Close" | translate }}
      </button>
    </ng-template>
  </ng-container>
</div>
