import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ethers } from "ethers";
import { firstValueFrom } from "rxjs";
import { PolygonRawGas, PolygonRawGasResponse } from "src/app/common/DTO/polygonscan/polygonscan-raw-gas.dto";
import { PolygonRawTx } from "src/app/common/DTO/polygonscan/polygonscan-raw-tx.dto";
import { PolygonRawUsdtTx } from "src/app/common/DTO/polygonscan/polygonscan-raw-usdt-tx.dto";
import { PolygonScanTxListDto } from "src/app/common/DTO/polygonscan/polygonscan-tx-list.dto";
import { PolygonUsdtListDto } from "src/app/common/DTO/polygonscan/polygonscan-usdt-list.dto";
import { PolygonUsdtAbi, PolygonUsdtAddress } from "src/app/common/constants/polygon-usdt";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class PolygonService {
  private readonly txPerPage = 10;
  private readonly usdtContractAddress = PolygonUsdtAddress;
  private readonly polygonRpc = "https://polygon-rpc.com";
  private polygonProvider: ethers.providers.JsonRpcProvider;

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {
    this.polygonProvider = new ethers.providers.JsonRpcProvider(this.polygonRpc);
  }

  public async getMaticTransactions(
    wallet: string,
    page: number,
    startTimestamp?: number,
    endTimestamp?: number
  ): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getMaticTxs(wallet, page, this.txPerPage, false, startTimestamp, endTimestamp);
    if (!response) return [];

    // If the rate limit reached, try again with the API key
    if (typeof response.result === "string" && response.result.includes("Missing/Invalid API Key")) {
      response = await this.getMaticTxs(wallet, page, this.txPerPage, true, startTimestamp, endTimestamp);
    }
    if (!response) return [];

    for (const tx of response.result) {
      const dto = new PolygonRawTx();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getUsdtTransactions(
    wallet: string,
    page: number,
    startTimestamp?: number,
    endTimestamp?: number
  ): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getUsdtTxs(wallet, page, this.txPerPage, false, startTimestamp, endTimestamp);
    if (!response) return [];

    if (typeof response.result === "string" && response.result.includes("Missing/Invalid API Key")) {
      response = await this.getUsdtTxs(wallet, page, this.txPerPage, true, startTimestamp, endTimestamp);
    }
    if (!response) return [];

    for (const tx of response.result) {
      const dto = new PolygonRawUsdtTx();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getGasPrice(): Promise<PolygonRawGas | null> {
    let response = await this.requestGasPrice(false);
    if (!response) return null;

    if (typeof response.result === "string" && response.result.includes("Missing/Invalid API Key")) {
      response = await this.requestGasPrice(true);
    }
    if (!response || typeof response.result === "string") return null;

    return response.result;
  }

  public async estimateMaticTransferCost(
    amount: string,
    networkGasPrice: number,
    toAddress?: string
  ): Promise<number> {
    let gasAmount = 21000;

    if (toAddress && amount) {
      try {
        const transaction = {
          to: toAddress,
          value: ethers.utils.parseEther(amount),
          gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
        };
        gasAmount = (await this.polygonProvider.estimateGas(transaction)).toNumber();
      } catch (error) {
        console.log(error);
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  public async estimatePolygonUsdtTransferCost(
    amount: string,
    networkGasPrice: number,
    fromAddress?: string,
    toAddress?: string
  ): Promise<number> {
    const usdtContract = new ethers.Contract(PolygonUsdtAddress, PolygonUsdtAbi, this.polygonProvider);
    let gasAmount = 77578;

    if (fromAddress && toAddress && amount) {
      try {
        gasAmount = (
          await usdtContract.estimateGas["transfer"](toAddress, ethers.utils.parseUnits(amount, 6))
        ).toNumber();
      } catch (error) {
        try {
          const manualGasLimit = 100000;
          const tx = {
            from: fromAddress,
            to: usdtContract.address,
            data: usdtContract.interface.encodeFunctionData("transfer", [
              toAddress,
              ethers.utils.parseUnits(amount, 6),
            ]),
            gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
            gasLimit: manualGasLimit,
          };
          gasAmount = (await this.polygonProvider.estimateGas(tx)).toNumber();
        } catch (error) {
          console.log(error);
        }
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  private parseRawTx(tx: PolygonRawTx | PolygonRawUsdtTx, userWallet: string): Transaction {
    const txDto = new Transaction();

    txDto.from = tx.from;
    txDto.to = tx.to;
    txDto.hash = tx.hash;
    txDto.createdAt = new Date(+tx.timeStamp * 1000);
    txDto.timestamp = +tx.timeStamp * 1000;
    txDto.fee = +tx.gasPrice * +tx.gasUsed;
    txDto.id = tx.transactionIndex;
    txDto.type = tx.from.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Polygon;

    if (tx instanceof PolygonRawTx) {
      txDto.amount = ethers.utils.formatUnits(tx.value || 0);
      txDto.status = tx.isError == "0" ? TxStatus.Approved : TxStatus.Canceled;
      txDto.token = CryptoToken.Matic;
      txDto.isCommission = tx.methodId === "0xa9059cbb";
    } else if (tx instanceof PolygonRawUsdtTx) {
      txDto.amount = ethers.utils.formatUnits(tx.value || 0, tx.tokenDecimal || 0);
      txDto.status = TxStatus.Approved;
      txDto.token = CryptoToken.PolygonUsdt;
    }

    return txDto;
  }

  private async getMaticTxs(
    wallet: string,
    page: number = 1,
    offset: number = 10,
    withApiKey?: boolean,
    startTimestamp?: number,
    endTimestamp?: number
  ) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";

    let startBlock = "0";
    if (startTimestamp) {
      const startBlockNumber = await this.getBlockNumber(startTimestamp, "after", withApiKey);
      if (startBlockNumber === null) {
        return new PolygonScanTxListDto();
      }
      if (startBlockNumber.result.includes("Missing/Invalid API Key")) {
        const emptyResponse = new PolygonScanTxListDto();
        emptyResponse.result = startBlockNumber.result;
        return emptyResponse;
      }
      startBlock = startBlockNumber.result;
    }

    let endBlock = "99999999";
    if (endTimestamp) {
      const endBlockNumber = await this.getBlockNumber(endTimestamp, "before", withApiKey);
      if (endBlockNumber === null) {
        return new PolygonScanTxListDto();
      }
      if (endBlockNumber.result.includes("Missing/Invalid API Key")) {
        const emptyResponse = new PolygonScanTxListDto();
        emptyResponse.result = endBlockNumber.result;
        return emptyResponse;
      }
      endBlock = endBlockNumber.result;
    }

    const searchParams = new URLSearchParams({
      module: "account",
      action: "txlist",
      address: wallet,
      startblock: startBlock,
      endblock: endBlock,
      page: page.toString(),
      offset: offset.toString(),
      sort: "desc",
    });
    if (apiKey) {
      searchParams.append("apikey", apiKey);
    }

    const uri = `${this._env.polygonScanApiUrl}?${searchParams.toString()}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonScanTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getUsdtTxs(
    wallet: string,
    page: number = 1,
    offset: number = 10,
    withApiKey?: boolean,
    startTimestamp?: number,
    endTimestamp?: number
  ) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";

    let startBlock = "0";
    if (startTimestamp) {
      const startBlockNumber = await this.getBlockNumber(startTimestamp, "after", withApiKey);
      if (startBlockNumber === null) {
        return new PolygonScanTxListDto();
      }
      if (startBlockNumber.result.includes("Missing/Invalid API Key")) {
        const emptyResponse = new PolygonScanTxListDto();
        emptyResponse.result = startBlockNumber.result;
        return emptyResponse;
      }
      startBlock = startBlockNumber.result;
    }

    let endBlock = "99999999";
    if (endTimestamp) {
      const endBlockNumber = await this.getBlockNumber(endTimestamp, "before", withApiKey);
      if (endBlockNumber === null) {
        return new PolygonScanTxListDto();
      }
      if (endBlockNumber.result.includes("Missing/Invalid API Key")) {
        const emptyResponse = new PolygonScanTxListDto();
        emptyResponse.result = endBlockNumber.result;
        return emptyResponse;
      }
      endBlock = endBlockNumber.result;
    }

    const searchParams = new URLSearchParams({
      module: "account",
      action: "tokentx",
      contractaddress: this.usdtContractAddress,
      address: wallet,
      startblock: startBlock,
      endblock: endBlock,
      page: page.toString(),
      offset: offset.toString(),
      sort: "desc",
    });
    if (apiKey) {
      searchParams.append("apikey", apiKey);
    }

    const uri = `${this._env.polygonScanApiUrl}?${searchParams.toString()}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonUsdtListDto;
    } catch (e) {
      return null;
    }
  }

  private async getBlockNumber(timestamp: number, closest: "before" | "after", withApiKey?: boolean) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";

    const searchParams = new URLSearchParams({
      module: "block",
      action: "getblocknobytime",
      timestamp: timestamp.toString(),
      closest,
    });
    if (apiKey) {
      searchParams.append("apikey", apiKey);
    }

    const uri = `${this._env.polygonScanApiUrl}?${searchParams.toString()}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as { result: string };
    } catch (e) {
      return null;
    }
  }

  private async requestGasPrice(withApiKey?: boolean) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";
    const uri = `${this._env.polygonScanApiUrl}?module=gastracker&action=gasoracle&apikey=${apiKey}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonRawGasResponse;
    } catch (error) {
      return null;
    }
  }
}
