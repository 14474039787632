<div
  class="flex flex-col gap-y-4 py-3 md:w-[calc(100vw-5.75rem)] md:flex-row md:justify-center md:gap-x-12 md:gap-y-0"
>
  <img [src]="slide?.imgSrc" alt="" class="w-[calc(100vw-2rem)] md:w-[27.5rem]" />

  <div class="flex flex-1 flex-col gap-y-9 md:max-w-4xl">
    <h1 class="whitespace-pre-line text-xl font-semibold md:text-5xl">
      {{ slide?.title?.common }}
      <br />
      <span class="text-green-400">{{ slide?.title?.highlighted }}</span>
    </h1>

    <div class="flex items-center gap-x-4">
      <span>Легально</span>
      <div class="h-1.5 w-1.5 rounded-full bg-blue-50"></div>
      <span>Надежно</span>
      <div class="h-1.5 w-1.5 rounded-full bg-blue-50"></div>
      <span>Безопасно</span>
    </div>

    <button class="btn-primary btn-medium max-md:hidden md:w-[25rem]" (click)="handleButtonClick()">
      Регистрация
    </button>
  </div>
</div>
