<div class="px-15 py-7 max-md:p-0">
  <button (click)="onClose()" class="absolute right-4 top-3 !m-0 md:right-2.5 md:top-2.5">
    <app-svg-icon name="close" class="h-8 w-8" />
  </button>

  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="1">
      <h4 class="text-center text-2xl font-semibold">{{ "Bank.Adding_card" | translate }}</h4>

      <form [formGroup]="addCardForm" (ngSubmit)="onCardSubmit()" class="mt-6">
        <div class="rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            [title]="'Bank.Card_number' | translate"
            [placeholder]="'Bank.Card_number_placeholder' | translate"
            formControlName="number"
            [errorMsg]="getNumberErrMsg"
            id="number"
            mask="0000 0000 0000 0000"
          />
          <app-input
            [title]="'Bank.Card_expiration_date' | translate"
            placeholder="мм/гг"
            type="text"
            formControlName="expiryAt"
            [errorMsg]="getExpiryAtErrMsg"
            id="expiryAt"
            mask="00/00"
            [dropSpecialCharacters]="false"
            class="mt-4 block"
            wrapperClasses="w-[7.5rem]"
          />
        </div>

        <button
          type="submit"
          class="btn-primary btn-medium mt-6 w-full"
          [disabled]="addCardForm.invalid || isPending"
        >
          {{ "Common.Next" | translate }}
        </button>
      </form>

      <p *ngIf="businessError" class="mt-4 text-sm text-danger">{{ businessError }}</p>
    </ng-template>

    <ng-template ngSwitchCase="2">
      <button
        type="button"
        class="absolute left-3 top-3 flex h-8 w-8 items-center justify-center rounded-lg bg-red-50 dark:bg-slate-800"
        (click)="onBack()"
      >
        <app-svg-icon name="actions/back" class="stroke-slate-400" />
      </button>

      <h4 class="text-center text-2xl font-semibold">Введите код</h4>
      <p class="mt-2 text-center font-medium dark:text-slate-200">
        Для привязки карты отправили код на номер <br />
        {{ maskedPhoneNumber | phoneDisplayer }}
      </p>

      <form [formGroup]="addCardOtpForm" (ngSubmit)="onCodeSubmit()" class="mt-6">
        <div class="rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            type="number"
            [title]="'Login.Sms_code' | translate"
            placeholder="______"
            formControlName="cardVerificationCode"
            [withTimer]="true"
            [timer]="otpTimer"
            (onRefresh)="sendCodeToPhone()"
          />
          <re-captcha
            *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
            (resolved)="captchaResolved($event)"
          ></re-captcha>
        </div>

        <p *ngIf="businessError" class="text-sm text-danger">{{ businessError }}</p>

        <button
          type="submit"
          class="btn-primary btn-medium mt-6 w-full"
          [disabled]="addCardOtpForm.invalid || isPending"
        >
          Добавить карту
        </button>
      </form>
    </ng-template>

    <ng-template ngSwitchCase="3">
      <h4 class="text-center text-2xl font-semibold">Успешно</h4>
      <div class="mt-10">
        <img src="assets/images/success-check-mark.png" alt="success" class="mx-auto w-[7.5rem]" />
        <p class="mt-6 text-center dark:text-slate-200">Карта добавлена</p>
      </div>
      <button class="btn-primary-dark btn-medium mt-10 w-full bg-gray-900" (click)="onClose()">
        Закрыть
      </button>
    </ng-template>
  </ng-container>
</div>
