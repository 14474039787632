<div class="relative inline-block">
  <button
    type="button"
    class="ml-1 flex h-5 w-5 shrink-0 items-center justify-center active:scale-90"
    (click)="copyValueToClipboard($event, t)"
    [ngbTooltip]="copiedMessage"
    #t="ngbTooltip"
    triggers="manual"
    container="body"
    tooltipClass="bg-accent text-text-secondary text-xs font-medium px-2 py-1 rounded z-[200]"
  >
    <app-svg-icon name="copy" class="text-slate-400 dark:text-white" />
  </button>

  <ng-template #copiedMessage>
    <p>
      {{ "Common.Copied" | translate }}
    </p>
  </ng-template>
</div>
