<div
  class="grid grid-cols-[15%_15%_20%_auto] items-center gap-x-6 px-6 py-2 font-medium dark:text-white max-md:flex max-md:flex-col max-md:items-stretch max-md:p-0"
>
  <div class="flex items-center justify-between">
    <div class="flex flex-wrap items-center gap-x-3">
      <img
        [src]="txDto.type === TxType.In ? 'assets/icons/receive.svg' : 'assets/icons/send.svg'"
        alt=""
        class="h-6 w-6 shrink-0"
      />
      {{ txDestination }}
      <span *ngIf="txDto.isCommission" class="grow text-sm text-slate-400">
        ({{ "Transaction.Commission_payment" | translate }})
      </span>
    </div>
    <div class="shrink-0 whitespace-pre text-slate-400 md:hidden">
      {{ txDate | customDate: "full" }}
    </div>
  </div>

  <div class="max-md:order-1 max-md:mt-5 max-md:flex max-md:items-center max-md:justify-between">
    <span>
      {{ balanceChange }}
      {{ tokenName }}
    </span>
    <div class="block md:hidden" [ngClass]="statusColor">{{ txStatus }}</div>
  </div>

  <div
    class="flex items-center gap-x-6 text-slate-400 dark:text-white max-md:mt-4 dark:max-md:text-slate-400"
  >
    <a
      target="_blank"
      [href]="txLink"
      rel="noopener noreferrer"
      class="block max-w-[12.5rem] truncate max-md:max-w-none max-md:font-medium"
    >
      <span class="md:hidden">ID: </span>
      {{ txDto.hash }}
    </a>
    <app-copy-btn [value]="txDto.hash" />
  </div>

  <div class="whitespace-pre text-slate-400 dark:text-white max-md:hidden">
    {{ txDate | customDate: "full" }}
  </div>

  <!-- <div *ngIf="fullSize" class="mr-3 h-10 w-10 max-md:h-5 max-md:w-5">
    <app-svg-icon
      name="arrow-up"
      class="stroke-select-bg"
      [ngClass]="{ 'block rotate-180': txDto.type === TxType.In }"
    />
  </div>
  <div class="flex items-center">
    <app-currency-icon [currency]="iconName" />
    <div class="ml-5 max-md:ml-2">
      <p class="font-semibold">
        {{ txDestination }}
        <span *ngIf="txDto.isCommission">({{ "Transaction.Commission_payment" | translate }})</span>
      </p>
      <p class="flex items-center gap-2 max-md:text-sm">
        <span>{{ txDate | customDate: "full" }}</span>
        <span class="h-4 w-4 rounded-full" [ngClass]="statusColor"></span>
        <span>{{ txStatus }}</span>
      </p>
      <div *ngIf="fullSize" class="text-2xl font-bold">
        {{ balanceChange }}
        {{ tokenName }}
      </div>
    </div>
    <div *ngIf="!fullSize" class="ml-auto text-2xl font-bold">
      {{ balanceChange }}
      {{ tokenName }}
    </div>
  </div>

  <div class="mt-3 space-y-2 max-md:w-full" [ngClass]="{ 'ml-auto': fullSize }">
    <p
      class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:border-b max-md:border-b-border-input-color max-md:pb-2 max-md:text-sm"
    >
      <span>{{ "Transaction.From" | translate }}:</span>
      <a
        [href]="returnWalletLink(txDto.from)"
        target="_blank"
        class="ml-auto truncate font-semibold max-md:order-1 max-md:flex-grow"
      >
        {{ txDto.from }}
      </a>
      <app-copy-btn [value]="txDto.from" />
    </p>
    <p
      class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:border-b max-md:border-b-border-input-color max-md:pb-2 max-md:text-sm"
    >
      <span>{{ "Transaction.To" | translate }}:</span>
      <a
        [href]="returnWalletLink(txDto.to)"
        target="_blank"
        class="ml-auto truncate font-semibold max-md:order-1 max-md:flex-grow"
      >
        {{ txDto.to }}
      </a>
      <app-copy-btn [value]="txDto.to" />
    </p>
    <p class="flex items-center gap-2 text-xl max-md:flex-wrap max-md:justify-between max-md:text-sm">
      <span>{{ "Transaction.Hash" | translate }}:</span>
      <a
        target="_blank"
        [href]="txLink"
        class="ml-auto max-w-[14.5rem] truncate font-semibold max-md:order-1 max-md:ml-0 max-md:flex-grow"
      >
        {{ txDto.hash }}
      </a>
      <app-copy-btn [value]="txDto.hash" />
    </p>
  </div> -->
</div>
