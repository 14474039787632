import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ChangePasswordDto } from "src/app/common/DTO/auth/change-password.dto";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { matchValidator } from "src/app/common/validators/match.validator";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-change-password-modal",
  templateUrl: "./change-password-modal.component.html",
  styleUrls: ["./change-password-modal.component.css"],
})
export class ChangePasswordModalComponent {
  @Input() userId: number | null = null;

  isPending = false;
  isSuccess = false;
  businessError: string | null = null;

  changePasswordForm: FormGroup;

  constructor(
    private _activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _translateService: TranslateService
  ) {
    this.changePasswordForm = new FormGroup(
      {
        oldPsw: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.MinPswLength),
          Validators.maxLength(ValidateConstants.MaxPswLength),
        ]),
        newPsw: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.MinPswLength),
          Validators.maxLength(ValidateConstants.MaxPswLength),
          Validators.pattern(ValidateConstants.PswPattern),
        ]),
        pswRepeat: new FormControl(null, [Validators.required]),
      },
      { validators: matchValidator("newPsw", "pswRepeat") }
    );
  }

  onClose() {
    this._activeModal.close();
  }

  async onChangePassword() {
    if (this.changePasswordForm.invalid || !this.userId) {
      return;
    }

    const dto: ChangePasswordDto = {
      oldPsw: this.changePasswordForm.value.oldPsw,
      newPsw: this.changePasswordForm.value.newPsw,
      userId: this.userId,
    };
    this.isPending = true;

    const res = await this._authService.changePassword(dto);

    if (res && res.withError) {
      if (res.errorCode === AuthErrorCode.IncorrectCode) {
        this.businessError = this._translateService.instant("Login.Wrong_password");
        this.isPending = false;
        return;
      }
      if (res.errorCode === AuthErrorCode.IncorrectPassword) {
        this.businessError = this._translateService.instant("Login.Wrong_password");
        this.isPending = false;
        return;
      }
      this.businessError = this._translateService.instant("Common.Unknown_error");
      this.isPending = false;
      return;
    }

    this.isSuccess = true;
    this.businessError = null;
    this.isPending = false;
    this.changePasswordForm.reset();
  }

  getFieldHasErrors(fieldName: string) {
    const field = this.changePasswordForm.get(fieldName);

    if (field?.value === null) {
      return null;
    }

    if (field?.invalid) {
      return "Неверный пароль";
    } else {
      return null;
    }
  }
}
