<div
  class="relative flex w-fit items-center justify-center gap-x-1 rounded-full bg-gray-100 p-1 dark:bg-gray-900"
>
  <button class="toggle" (click)="setTheme('light')">
    <app-svg-icon name="sun" class="block aspect-square w-6 max-md:w-5" />
  </button>
  <button class="toggle" (click)="setTheme('dark')">
    <app-svg-icon name="moon" class="block aspect-square w-6 max-md:w-5" />
  </button>

  <span
    class="slider"
    [ngClass]="{ 'translate-x-0': theme === 'light', 'translate-x-full': theme === 'dark' }"
  ></span>
</div>
