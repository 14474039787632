<ng-container *ngIf="!isLarge; else large">
  <footer
    class="flex items-center gap-x-6 bg-gray-900 px-11.5 py-6 text-sm text-red-25/60 dark:bg-slate-800 max-md:items-start max-md:px-4 max-md:py-3"
  >
    <img src="assets/images/parent-logo-dark.svg" alt="" class="w-16" />
    <span>*Лицензия CD#0002 на осуществление деятельности крипто-депозитария от 13.11.2023</span>
    <span>*Лицензия CS#0006 на осуществление деятельности крипто-магазина от 08.08.2023</span>
  </footer>
</ng-container>

<ng-template #large>
  <footer class="space-y-px bg-black">
    <div
      class="flex flex-col gap-y-6 bg-gray-900 px-4 pb-8 pt-5 md:grid md:h-56 md:grid-cols-4 md:items-start md:gap-y-0 md:px-11.5 md:pb-0 md:pt-10"
    >
      <div class="flex justify-between gap-y-2 text-sm text-red-25/50 md:flex-col md:justify-normal">
        <img src="assets/images/logo-dark.svg" alt="" class="w-32" />
        <div class="space-y-2">
          <span class="block">©Paynet Crypto 2024</span>
          <a href="#" class="block text-end underline md:text-start">Публичная Оферта</a>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-red-25/50">Контакты</h3>
        <div class="space-y-2">
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/phone.svg" alt="" class="h-6 w-6.5" />
            <span>+998 71 2023777</span>
          </a>
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/mail.svg" alt="" class="h-6 w-6.5" />
            <span>info&#64;paynetcrypto.uz</span>
          </a>
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/telegram.svg" alt="" class="h-6 w-6.5" />
            <span>&#64;paynet_crypto_bot</span>
          </a>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-red-25/50">Адрес</h3>
        <span class="flex items-start gap-x-2">
          <img src="assets/icons/contacts/location.svg" alt="" class="h-6 w-6.5" />
          <span class="whitespace-pre-line">
            {{ "Республика Узбекистан\n100100, г. Ташкент,\nул. Шота Руставели 53 Б" }}
          </span>
        </span>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-red-25/50">Мы в социальных сетях</h3>
        <div class="flex gap-x-2">
          <a href="#">
            <img src="assets/icons/socials/youtube.svg" alt="" class="h-9.5 w-9.5" />
          </a>
          <a href="#">
            <img src="assets/icons/socials/facebook.svg" alt="" class="h-9.5 w-9.5" />
          </a>
          <a href="#">
            <img src="assets/icons/socials/instagram.svg" alt="" class="h-9.5 w-9.5" />
          </a>
          <a href="#">
            <img src="assets/icons/socials/telegram.svg" alt="" class="h-9.5 w-9.5" />
          </a>
          <a href="#">
            <img src="assets/icons/socials/linkedin.svg" alt="" class="h-9.5 w-9.5" />
          </a>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col gap-y-3 bg-gray-900 px-4 py-6 text-sm text-gray-25/60 md:flex-row md:gap-x-6 md:gap-y-0 md:px-11.5"
    >
      <span>*Лицензия CD#0002 на осуществление деятельности крипто-депозитария от 13.11.2023</span>
      <span>*Лицензия CS#0006 на осуществление деятельности крипто-магазина от 08.08.2023</span>
    </div>
  </footer>
</ng-template>
