import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/common/constants/constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { BuyOrderDto } from "src/app/common/DTO/crypto/buy-order.dto";
import { SellOrderDto } from "src/app/common/DTO/crypto/sell-order.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { CryptoErrorCode } from "src/app/common/enums/crypto-error-code.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { CardService } from "src/app/services/card.service";
import { CommissionService } from "src/app/services/commission.service";
import { CryptoService } from "src/app/services/crypto.service";
import { GeneralPanelService } from "src/app/services/general-panel.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { ToastService } from "src/app/services/toast.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-swap-modal",
  templateUrl: "./swap-modal.component.html",
  styleUrls: ["./swap-modal.component.css"],
})
export class SwapModalComponent implements OnInit {
  @Input() walletBalance: WalletBalanceDto | null = null;

  step: "input" | "confirm" | "success" | "error" = "input";

  CryptoSymbol = CryptoSymbol;

  fromCurrency = CryptoSymbol.Uzs;
  toCurrency = CryptoSymbol.Usdt;

  wallets: WalletDto[] = [];
  fromWalletBalance = this.walletBalance;

  rates: RateDto[] = [];

  bankCards: CardDto[] = [];
  selectedBankCard: CardDto | null = null;

  amount: number | null = null;

  serviceFeePercents = 0;
  feeAmount = 0;
  uzsAmount = 0;
  isPending = false;
  networkFee = 0;

  swapForm: FormGroup;

  // private $amount = new Subject<number>();
  private polygonGasPrice = 0;
  // private currencyRateFactor = 0.15;

  public confirmationTimeCounter: number = 0;
  private _timer: any;

  readonly currencies = [
    CryptoSymbol.Uzs,
    CryptoSymbol.Trx,
    CryptoSymbol.Usdt,
    CryptoSymbol.Matic,
    CryptoSymbol.PolygonUsdt,
    CryptoSymbol.Ton,
    CryptoSymbol.TonUsdt,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];

  constructor(
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _commissionService: CommissionService,
    private readonly _walletService: WalletService,
    private readonly _ratesService: RatesService,
    private readonly _router: Router,
    private readonly _activeModal: NgbActiveModal,
    private readonly _cryptoService: CryptoService,
    private readonly _toastService: ToastService,
    private readonly _polygonService: PolygonService,
    private readonly _generalPanelService: GeneralPanelService
  ) {
    this.swapForm = new FormGroup({
      fromAmount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      toAmount: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.min(Number.MIN_VALUE),
      ]),
    });
    // this.$amount
    //   .pipe(
    //     debounceTime(300),
    //     switchMap(amount =>
    //       this._commissionService.getCommissionWithParams(amount, this.fromWalletBalance!.currency)
    //     )
    //   )
    //   .subscribe(async x => {
    //     if (x.withError || x.params == null) {
    //       this.serviceFeePercents = 0;
    //     } else {
    //       this.serviceFeePercents = x.params.percents;
    //     }
    //     await this.calculateNetworkFee();
    //     this.feeAmount =
    //       this.serviceFeePercents && this.uzsAmount ? (this.uzsAmount * this.serviceFeePercents) / 100 : 0;

    //     // TEMP: while to amount is disabled
    //     const formattedOutputAmount = Number(this.totalAmount.toFixed(8));
    //     this.swapForm.controls["toAmount"].setValue(formattedOutputAmount > 0 ? formattedOutputAmount : 0);
    //   });
  }

  async ngOnInit() {
    if (this.walletBalance) {
      this.fromCurrency = this.walletBalance.currency;
      this.fromWalletBalance = this.walletBalance;
      this.toCurrency = CryptoSymbol.Uzs;
    }
    await this.getWallets();
    await this.getRates();
    await this.getRateFactor();
    await this.getGasPrice();
    await this.calculateNetworkFee();
    this.loadBankCards();
  }

  onClose() {
    this._activeModal.close();
  }

  goToBankCards() {
    const route = `${RouteConstants.depositary}/${RouteConstants.cards}`;
    this._router.navigateByUrl(route);
    this.onClose();
  }

  goToWallet() {
    this._router.navigateByUrl(RouteConstants.wallet);
    this.onClose();
  }

  public get currencySymbol() {
    if (this.fromWalletBalance === null) {
      return "Unknown";
    }
    return getCurrencyName(this.fromWalletBalance.currency);
  }

  public get currencyNetwork() {
    switch (this.fromWalletBalance?.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRC20";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "Ton";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "";
    }
  }

  public get nativeCurrencyName() {
    switch (this.fromWalletBalance?.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "TON";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public get fromCurrencyName() {
    return getCurrencyName(this.fromCurrency);
  }

  public get toCurrencyName() {
    return getCurrencyName(this.toCurrency);
  }

  public selectFromCurrency(currency: CryptoSymbol) {
    if (currency === this.fromCurrency) {
      return;
    }
    this.fromCurrency = currency;
    if (currency === CryptoSymbol.Uzs) {
      this.toCurrency = CryptoSymbol.Usdt;
    } else {
      this.wallets.forEach(wallet => {
        wallet.balances.forEach(balance => {
          if (balance.currency === currency) {
            this.fromWalletBalance = balance;
          }
        });
      });
    }
    if (currency !== CryptoSymbol.Uzs && this.toCurrency !== CryptoSymbol.Uzs) {
      this.toCurrency = CryptoSymbol.Uzs;
    }
    this.calculateToAmount();
  }

  public selectToCurrency(currency: CryptoSymbol) {
    if (currency === this.toCurrency) {
      return;
    }
    this.toCurrency = currency;
    if (currency === CryptoSymbol.Uzs) {
      this.fromCurrency = CryptoSymbol.Usdt;
    } else if (this.fromCurrency !== CryptoSymbol.Uzs) {
      this.fromCurrency = CryptoSymbol.Uzs;
    }
    this.calculateToAmount();
  }

  public async calculateToAmount() {
    const fromAmount = this.swapForm.controls["fromAmount"]?.value;
    const toAmountControl = this.swapForm.controls["toAmount"];
    if (!fromAmount) {
      toAmountControl?.setValue?.(null);
      return;
    }
    let convertedAmount = 0;
    const fromRate = fromAmount * this.rates.find(x => x.symbol == this.fromCurrency)!.usd;
    const toRate = this.rates.find(x => x.symbol == this.toCurrency)!.usd;
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      convertedAmount = Math.floor(fromRate / toRate);
    } else {
      convertedAmount = +(fromRate / toRate).toFixed(3);
    }
    toAmountControl?.setValue?.(convertedAmount.toString());
    await this.calculateNetworkFee();

    // if (this.toCurrency === CryptoSymbol.Uzs) {
    //   this.amount = fromAmount;
    //   this.calculateUzs();
    // }
  }

  // calculateUzs() {
  //   if (!this.amount || !this.fromWalletBalance || +this.amount === 0) {
  //     this.uzsAmount = 0;
  //     return;
  //   }
  //   const convertedToUzs = ConvertCurrencyHelper.convertToUzs(
  //     this.amount,
  //     this.fromWalletBalance.currency,
  //     this.rates
  //   );
  //   let adjustment = 0;
  //   if (this.toCurrency === CryptoSymbol.Uzs) {
  //     adjustment = 1 - this.currencyRateFactor / 100;
  //   } else {
  //     adjustment = 1 + this.currencyRateFactor / 100;
  //   }
  //   this.uzsAmount = convertedToUzs * adjustment;
  //   this.$amount.next(this.amount);
  // }

  public get fromAmountError() {
    const amount = this.swapForm.controls["fromAmount"]?.value ?? 0;
    if (!amount) {
      return null;
    }
    if (+amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    // if (this.totalAmount <= 0) {
    //   return this._translateService.instant("Sell.Min_value_error");
    // }
    return null;
  }

  public get toAmountError() {
    const amount = this.swapForm.controls["toAmount"]?.value ?? 0;
    if (!amount) {
      return null;
    }
    if (+amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    return null;
  }

  onInputSubmit() {
    const fromAmount = this.swapForm.get("fromAmount")?.value;
    const toAmount = this.swapForm.get("toAmount")?.value;
    if (this.swapForm.invalid || !toAmount || +toAmount === 0 || !fromAmount || +fromAmount === 0) {
      return;
    }

    this.step = "confirm";

    this.confirmationTimeCounter = 30;
    this._timer = setInterval(() => {
      if (this.confirmationTimeCounter > 0) {
        this.confirmationTimeCounter--;
      } else {
        this.backToInput();
        return;
      }
    }, Constants.MsInSec);
  }

  backToInput() {
    this.step = "input";
    clearInterval(this._timer);
  }

  onCheckout() {
    if (!this.selectedBankCard) {
      return;
    }
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      this.doBuy();
    }
    if (this.toCurrency === CryptoSymbol.Uzs) {
      this.doSell();
    }
    clearInterval(this._timer);
  }

  public get networkFeeUzs() {
    if (!this.fromWalletBalance || !this.networkFee || this.rates.length === 0) {
      return 0;
    }

    const networkFeeUzs = ConvertCurrencyHelper.convertToUzs(
      this.networkFee,
      this.fromWalletBalance!.currency,
      this.rates
    );
    return networkFeeUzs;
  }

  // public get totalAmount() {
  //   const amount = this.swapForm.controls["fromAmount"]?.value;

  //   if (!amount) {
  //     return 0;
  //   }

  //   if (this.fromCurrency === CryptoSymbol.Uzs) {
  //     return this.uzsAmount + this.feeAmount + this.networkFeeUzs;
  //   } else if (this.toCurrency === CryptoSymbol.Uzs) {
  //     return this.uzsAmount - this.feeAmount - this.networkFeeUzs;
  //   } else {
  //     return 0;
  //   }
  // }

  public get oneUzsCurrencyAmount(): number {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    let selectedCurrency;
    if (this.toCurrency === CryptoSymbol.Uzs) {
      selectedCurrency = this.fromCurrency;
    } else if (this.fromCurrency === CryptoSymbol.Uzs) {
      selectedCurrency = this.toCurrency;
    } else {
      return 0;
    }
    const convertedToUzs = ConvertCurrencyHelper.convertToUzs(1, selectedCurrency, this.rates);
    return convertedToUzs;
  }

  private async calculateNetworkFee() {
    if (this.fromWalletBalance === null) {
      return;
    }
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency: this.fromWalletBalance.currency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.amount?.toString() ?? "0",
    });
  }

  private async doSell() {
    this.isPending = true;

    const amount = this.swapForm.controls["toAmount"]?.value ?? 0;

    const order: SellOrderDto = {
      sellAmount: Number(amount),
      sellCurrency: this.toCurrency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.sellCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Sell.Sell_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
      this.step = "error";
    } else {
      this.step = "success";
    }
    this.isPending = false;
  }

  private async doBuy() {
    this.isPending = true;

    const amount = this.swapForm.controls["fromAmount"]?.value ?? 0;

    const order: BuyOrderDto = {
      buyAmount: Number(amount),
      buyCurrency: this.fromCurrency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.buyCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Buy.Buy_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
      this.step = "error";
    } else {
      this.step = "success";
    }
    this.isPending = false;
  }

  private async loadBankCards() {
    const res = await this._cardService.getCards();
    if (res.withError || !res.params) {
      return;
    }
    this.bankCards = res.params;
    this.selectedBankCard = this.bankCards[0];
  }

  private async getWallets() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    if (res.withError || !res.params) {
      return;
    }
    this.rates = res.params;
  }

  private async getGasPrice() {
    if (
      this.fromWalletBalance?.currency === CryptoSymbol.Matic ||
      this.fromWalletBalance?.currency === CryptoSymbol.PolygonUsdt
    ) {
      const polygonRes = await this._polygonService.getGasPrice();
      this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
    }
  }

  private async getRateFactor() {
    const res = await this._generalPanelService.getGeneralPanel();
    if (res.params) {
      // this.currencyRateFactor = res.params.factor;
    }
  }

  ngOnDestroy() {
    clearInterval(this._timer);
  }
}
