import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { VerificationStatus } from "src/app/common/enums/verification-status.enum";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { AddBankCardModalComponent } from "src/app/components/add-bank-card-modal/add-bank-card-modal.component";
import { DeleteBankCardModalComponent } from "src/app/components/delete-bank-card-modal/delete-bank-card-modal.component";
import { CardService } from "src/app/services/card.service";

@Component({
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.css"],
})
export class CardsComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public cardList: CardDto[] = [];
  public user: UserDto = new UserDto();
  public verificationStatus = VerificationStatus.Required;
  public VerificationStatus = VerificationStatus;
  public showConfirmationDeleteForId: number | null = null;

  constructor(
    private readonly _cardService: CardService,
    private readonly _modalService: NgbModal,
    private readonly _userResolver: UserResolver
  ) {}

  async ngOnInit() {
    const user = this._userResolver.user;
    if (user) {
      this.user = user;
      if (this.user.kyc == null) {
        this.verificationStatus = VerificationStatus.Required;
      } else {
        this.verificationStatus = VerificationStatus.Verified;
      }
    }

    await this.setCards();
  }

  public get isUserBlocked(): boolean {
    return this.user.blackList != null;
  }

  public get canAddCard(): boolean {
    return (
      this.cardList.length > 0 &&
      this.cardList.length < Constants.MaxCardCount &&
      this.verificationStatus === VerificationStatus.Verified
    );
  }

  public openAddBankCardModal() {
    const modalRef = this._modalService.open(AddBankCardModalComponent);
    modalRef.componentInstance.userPhoneNumber = this.user.phoneNumber;
    modalRef.componentInstance.onAddCard.subscribe(() => {
      this.setCards();
    });
    modalRef.componentInstance.onCardAdded.subscribe(() => {
      this.setCards();
    });
  }

  public openDeleteCardModal(cardId: number) {
    const card = this.cardList.find(x => x.id == cardId)!;
    const modalRef = this._modalService.open(DeleteBankCardModalComponent);
    modalRef.componentInstance.card = card;
    modalRef.componentInstance.cardIndex = this.cardList.indexOf(card);
    modalRef.componentInstance.onDelete.subscribe(() => {
      this.cardList = this.cardList.filter(x => x.id != cardId);
      modalRef.close();
    });
  }

  private async setCards(): Promise<void> {
    const res = await this._cardService.getCards();
    if (res.withError) {
      return;
    }
    this.cardList = res.params!;
  }
}
