<button (click)="onClose()" class="absolute right-2 top-2 max-md:right-0 max-md:top-0">
  <app-svg-icon name="actions/close" class="block h-8 w-8 text-black dark:text-white" />
</button>

<div class="px-15 py-7 max-md:p-0">
  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="phone">
      <h1 class="mb-6 text-center text-xl font-semibold">Регистрация</h1>

      <form [formGroup]="phoneForm" (ngSubmit)="submitPassword()" class="flex flex-col gap-y-6">
        <div class="flex flex-col gap-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            id="phoneNumber"
            type="phone"
            formControlName="phoneNumber"
            [title]="'Login.Phone_number' | translate"
            placeholder="11 222 33 44"
            mask="00 000 00 00"
            [errorMsg]="phoneNumberError"
            [autofocus]="true"
          />

          <app-input
            type="password"
            [title]="'Login.Password' | translate"
            placeholder="********"
            formControlName="psw"
            id="psw"
            [autocomplete]="'off'"
            [autofocus]="true"
            [withPasswordToggle]="true"
          />

          <app-password-checker [password]="phoneForm.get('psw')?.value" />

          <input
            id="checkDepositary"
            type="checkbox"
            formControlName="checkDepositary"
            class="customCheckbox"
          />
          <label for="checkDepositary">
            <span>
              {{ "Login.Agree_1" | translate }}
              <a
                [href]="'assets/public-offers/' + language + '/offer-dep-' + language + '.pdf'"
                target="_blank"
                class="underline underline-offset-2"
              >
                Публичной оферты
              </a>
            </span>
          </label>
        </div>

        <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

        <button type="submit" class="btn-primary btn-medium" [disabled]="phoneForm.invalid || isPending">
          Зарегистрироваться
        </button>
        <button type="button" class="btn-text" (click)="openSignInModal()">Войти</button>
      </form>
    </ng-template>

    <ng-template ngSwitchCase="otp">
      <form [formGroup]="otpForm" (ngSubmit)="submitOtp()">
        <h1 class="mb-2 text-center text-xl font-semibold">Введите код</h1>
        <p class="mb-6 text-center dark:text-slate-200">
          Для подтверждения телефона отправили код на номер <br />
          +998{{ phoneNumber }}
        </p>

        <div class="flex flex-col gap-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
          <app-input
            id="otp"
            type="number"
            formControlName="code"
            [title]="'Login.Sms_code' | translate"
            placeholder="------"
            [errorMsg]="codeError"
            [autocomplete]="'off'"
            [autofocus]="true"
            [withTimer]="true"
            [timer]="otpTimer"
            (onRefresh)="sendConfirmCode()"
          />
        </div>

        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

        <div class="mt-6 flex items-stretch gap-6">
          <button
            type="submit"
            class="btn-primary btn-medium w-full"
            [disabled]="otpForm.invalid || isPending"
          >
            {{ "Login.Enter" | translate }}
          </button>
        </div>
      </form>
    </ng-template>
  </ng-container>
</div>
