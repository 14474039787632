<header
  class="flex items-center justify-between bg-white p-4 dark:bg-slate-800 md:px-11.5 md:py-5"
  [ngClass]="{ '!bg-transparent': transparent }"
>
  <a *ngIf="isAuth; else plainLogo" [routerLink]="'/' + RouteConstants.depositary">
    <app-logo [theme]="mode" [transparent]="transparent" />
  </a>
  <ng-template #plainLogo>
    <app-logo [theme]="mode" [transparent]="transparent" />
  </ng-template>

  <!-- Desktop -->
  <div
    *ngIf="withAuthState && isAuth; else unauthorizedNavigation"
    class="hidden items-center gap-x-6 md:flex"
  >
    <div class="flex items-center gap-x-4">
      <app-language-selector [size]="isMobile ? 'sm' : 'md'" />
      <button class="h-6 w-6 text-slate-400 dark:text-white">
        <app-svg-icon name="eye-on" />
      </button>
    </div>

    <div
      class="flex gap-x-4 border-l border-gray-100 pl-6 text-gray-900 dark:border-slate-400 dark:text-white"
    >
      <div class="space-y-1">
        <a
          [routerLink]="'/' + RouteConstants.depositary + '/' + RouteConstants.cards"
          class="btn-flat flex items-center gap-x-2"
        >
          <span class="text-lg font-medium">Карты</span>
          <app-svg-icon name="card" class="h-6 w-6" />
        </a>
        <span class="block text-sm font-medium text-slate-400">Добавлено: 2</span>
      </div>

      <div class="flex items-start gap-x-2">
        <div class="space-y-1">
          <span class="block text-lg font-medium">+{{ user.phoneNumber }}</span>
          <span class="block text-sm font-medium text-slate-400">ID: {{ user.id }}</span>
        </div>
        <div class="dropdown mt-1" ngbDropdown>
          <div id="custom-dropdown" ngbDropdownToggle class="dropdown__toggle text-slate-400 dark:text-white">
            <app-svg-icon name="profile-light" class="block h-6 w-6" />
            <app-svg-icon name="chevron" class="block h-3 w-3" />
          </div>

          <div aria-labelledby="custom-dropdown" class="dropdown__list" ngbDropdownMenu>
            <div *ngFor="let item of mobileMenuItems" class="dropdown__item" ngbDropdownItem>
              <a [routerLink]="item.link">{{ item.title | translate }}</a>
            </div>
            <button class="dropdown__item" (click)="openSettingsModal()" ngbDropdownItem>Настройки</button>
            <button
              class="mb-2 flex w-full gap-x-1 border-t border-gray-50 py-2 text-red-500 dark:border-slate-700 dark:text-white dark:hover:text-red-500"
              (click)="exit()"
              ngbDropdownItem
            >
              <span>Выход</span>
              <app-svg-icon name="actions/logout" class="h-6 w-6" />
            </button>
            <app-theme-selector />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div *ngIf="withAuthState && isAuth" class="flex items-center gap-x-6 md:hidden">
    <div>
      <app-language-selector size="sm" />
    </div>
    <button class="flex items-center gap-x-1" (click)="openMobileMenuModal(mobileMenuModal)">
      <app-svg-icon name="profile-light" class="h-6 w-6" />
      <app-svg-icon name="chevron" class="h-3 w-3" />
    </button>
  </div>

  <ng-template #unauthorizedNavigation>
    <div class="flex items-center gap-x-6">
      <app-language-selector [size]="isMobile ? 'sm' : 'md'" />

      <div class="flex gap-x-4 max-md:hidden">
        <button class="btn-outline btn-small" (click)="openSignUpModal()">Регистрация</button>
        <button class="btn-outline btn-small" (click)="openSignInModal()">Вход</button>
      </div>

      <button class="md:hidden" (click)="openSignInModal()">
        <img src="assets/icons/profile-dark.svg" alt="" class="h-8 w-8" />
      </button>
    </div>
  </ng-template>
</header>

<ng-template #mobileMenuModal let-modal>
  <div class="flex items-center justify-between gap-x-2">
    <app-logo [theme]="mode" />
    <app-language-selector size="sm" class="ml-auto" />
    <button
      (click)="modal.close()"
      class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100 text-black dark:bg-gray-900 dark:text-white"
    >
      <app-svg-icon name="close" class="block h-4 w-4" />
    </button>
  </div>

  <div class="mt-12 flex flex-col items-center gap-y-6">
    <ng-container *ngIf="isTelegramMiniApp">
      <button
        class="space-y-0.5"
        (click)="handleTonWalletConnectOrDisconnectClick(disconnectWalletConfirmationModal, modal)"
      >
        <div class="flex items-center gap-x-2">
          <span class="font-medium">Telegram кошелек</span>
          <img src="assets/icons/telegram-wallet.jpg" alt="" class="h-6 w-6 rounded-md" />
        </div>

        <span class="block text-sm font-medium" [ngClass]="{ 'text-green-400': !tonWalletAddress }">
          {{ tonWalletAddress ? "Подключен" : "Подключить" }}
        </span>
      </button>
    </ng-container>

    <a routerLink="/depositary/cards" class="font-medium" (click)="modal.close()"> Карты </a>

    <a
      *ngFor="let item of mobileMenuItems"
      [routerLink]="item.link"
      class="py-2 font-medium"
      (click)="modal.close()"
    >
      {{ item.title | translate }}
    </a>

    <button class="py-2 text-start font-medium" (click)="openSettingsModal(); modal.close()">
      Настройки аккаунта
    </button>

    <button class="py-2 font-medium text-red-500" (click)="exit(); modal.close()">Выход</button>
  </div>

  <div class="mt-12 space-y-0.5 text-center text-slate-400 dark:text-slate-200">
    <p>&copy; Paynet Crypto 2024</p>
    <a href="/" target="_blank" rel="noopener noreferrer" class="btn-flat underline">Публичная оферта</a>
  </div>

  <app-theme-selector class="mx-auto mt-6 w-fit" />
</ng-template>

<ng-template #disconnectWalletConfirmationModal let-modal>
  <div class="mx-auto flex w-[18.75rem] flex-col items-center justify-center space-y-6 py-7 max-md:p-0">
    <h4 class="text-2xl font-semibold">Отключение кошелька</h4>
    <p class="text-center">Уверены, что хотите отключить Telegram кошелек?</p>

    <div class="grid w-full grid-cols-2 gap-x-4">
      <button class="btn-primary-danger btn-medium" (click)="handleTonWalletDisconnect(modal)">Да</button>
      <button class="btn-primary-dark btn-medium" (click)="modal.close()">Нет</button>
    </div>
  </div>
</ng-template>
