<div
  class="relative rounded-full"
  [ngSwitch]="currencyIconName"
  [ngClass]="{
    'h-16 w-16 max-md:h-12 max-md:w-12': size === 'md',
    'h-9 w-9 max-md:h-8 max-md:w-8': size === 'sm',
    'h-8 w-8 max-md:h-6 max-md:w-6': size === 'xs'
  }"
>
  <ng-template ngSwitchCase="blank">
    <img src="assets/icons/currency/blank.png" alt="blank" />
  </ng-template>

  <ng-template ngSwitchCase="tron">
    <img src="assets/icons/currency/tron.png" alt="tron" />
  </ng-template>

  <ng-template ngSwitchCase="matic">
    <img src="assets/icons/currency/polygon.png" alt="matic" />
  </ng-template>

  <ng-template ngSwitchCase="usdt-tron">
    <img src="assets/icons/currency/usdt.png" alt="usdt" />
    <div
      class="absolute right-0 top-0 rounded-full border-2 border-text-secondary"
      [ngClass]="{
        'h-8 w-8 max-md:h-6 max-md:w-6': size === 'md',
        'h-4.5 w-4.5 max-md:h-4 max-md:w-4': size === 'sm',
        'h-4 w-4 max-md:h-3 max-md:w-3': size === 'xs'
      }"
    >
      <img src="assets/icons/currency/tron.png" alt="tron" />
    </div>
  </ng-template>

  <ng-template ngSwitchCase="usdt-matic">
    <img src="assets/icons/currency/usdt.png" alt="usdt" />
    <div
      class="absolute right-0 top-0 rounded-full border-2 border-text-secondary"
      [ngClass]="{
        'h-8 w-8 max-md:h-6 max-md:w-6': size === 'md',
        'h-4.5 w-4.5 max-md:h-4 max-md:w-4': size === 'sm',
        'h-4 w-4 max-md:h-3 max-md:w-3': size === 'xs'
      }"
    >
      <img src="assets/icons/currency/polygon.png" alt="polygon" />
    </div>
  </ng-template>

  <ng-template ngSwitchCase="usdt">
    <img src="assets/icons/currency/usdt.png" alt="usdt" />
  </ng-template>

  <ng-template ngSwitchCase="ton">
    <img src="assets/icons/currency/ton.png" alt="ton" />
  </ng-template>

  <ng-template ngSwitchCase="usdt-ton">
    <img src="assets/icons/currency/usdt.png" alt="usdt" />
    <div
      class="absolute right-0 top-0 rounded-full border-2 border-text-secondary"
      [ngClass]="{ 'h-8 w-8': size === 'md', 'h-4.5 w-4.5': size === 'sm', 'h-4 w-4': size === 'xs' }"
    >
      <img src="assets/icons/currency/ton.png" alt="polygon" />
    </div>
  </ng-template>

  <ng-template ngSwitchCase="not">
    <img src="assets/icons/currency/not.png" alt="not" />
  </ng-template>

  <ng-template ngSwitchCase="bitcoin">
    <img src="assets/icons/currency/bitcoin.png" alt="bitcoin" />
  </ng-template>

  <ng-template ngSwitchCase="uzs">
    <img src="assets/icons/currency/uzs.png" alt="uzs" />
  </ng-template>

  <ng-template ngSwitchCase="usd">
    <img src="assets/icons/currency/usd.png" alt="usd" />
  </ng-template>
</div>
