import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.css"],
})
export class SvgIconComponent implements OnInit, OnChanges {
  @Input() public name?: string;

  public svgIcon: any;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.loadSvgIcon(this.name);
  }

  ngOnChanges(): void {
    this.loadSvgIcon(this.name);
  }

  private loadSvgIcon(name?: string): void {
    if (!name) {
      this.svgIcon = "";
      return;
    }
    const iconPath = `assets/icons/${name}.svg`;
    this.httpClient.get(iconPath, { responseType: "text" }).subscribe({
      next: value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      },
      error: errors => {
        this.svgIcon = "";
      },
    });
  }
}
