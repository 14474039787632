<div class="relative overflow-hidden rounded-2xl max-md:h-full max-md:rounded-none">
  <div
    class="sticky top-0 flex items-center justify-between border-b border-gray-100 bg-white p-5 dark:border-slate-800 dark:bg-slate-900 max-md:border-none max-md:p-0"
  >
    <button
      class="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-25 dark:bg-slate-700 md:hidden"
      (click)="onClose()"
    >
      <app-svg-icon name="actions/back" />
    </button>
    <h2 class="text-2xl font-semibold">Настройки</h2>
    <button (click)="onClose()" class="flex h-8 w-8 items-center justify-center">
      <app-svg-icon name="actions/close"></app-svg-icon>
    </button>
  </div>

  <section class="relative grid grid-cols-[15.625rem_34.375rem] max-md:block max-md:h-fill">
    <aside
      class="sticky top-0 flex flex-col border-r border-gray-50 py-5 dark:border-slate-800 max-md:border-none max-md:p-0"
    >
      <ul
        class="max-md:mt-6 max-md:flex max-md:items-center max-md:gap-x-3 max-md:overflow-x-auto max-md:pb-4"
      >
        <li *ngFor="let item of SETTINGS_TABS">
          <button
            (click)="onTabSelect(item)"
            class="flex w-full items-center gap-x-3 px-5 py-4 hover:bg-green-400/5 max-md:gap-x-1 max-md:rounded-2xl max-md:border max-md:border-gray-50 max-md:px-4 max-md:py-3"
            [ngClass]="{
              'border-r border-green-400 bg-green-400/10 hover:bg-green-400/10 max-md:border max-md:border-green-400':
                item.id === currentTab.id
            }"
          >
            <app-svg-icon
              [name]="item.icon"
              class="h-6 w-6 shrink-0 text-gray-900"
              [ngClass]="{
                'text-green-400': item.id === currentTab.id
              }"
            />
            <span class="text-lg font-medium max-md:whitespace-nowrap">{{ item.title }}</span>
          </button>
        </li>
      </ul>
    </aside>

    <div
      class="max-h-[80dvh] overflow-y-auto bg-gray-25 px-7 py-5 dark:bg-slate-900 max-md:h-fill max-md:p-0"
    >
      <ng-container [ngSwitch]="currentTab.id">
        <app-change-password-modal *ngSwitchCase="'password'" [userId]="user?.id || null" />
      </ng-container>
    </div>
  </section>
</div>
