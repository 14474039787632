import { Component, HostListener, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { EventData } from "src/app/common/models/event-data";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.css"],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() size: "md" | "sm" = "md";

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.getResolutionOffsetValue();
  }

  public LanguageConstants = LanguageConstants;

  public languages: string[] = [LanguageConstants.RUSSIAN, LanguageConstants.UZBEK];
  public currentLanguage: LanguageConstants = LanguageConstants.RUSSIAN;

  private resolutionOffsetValue = 2.5;

  constructor(
    private readonly _translate: TranslateService,
    private readonly _localStorage: LocalStorageService,
    private readonly _eventBusService: EventBusService
  ) {
    this._eventBusService.handle(EventTypeConstants.ChangeLanguage, (language: string) => {
      this.currentLanguage = language;
    });
  }

  async ngOnInit() {
    const lsLang = await this._localStorage.getLanguage();
    if (lsLang) {
      this.currentLanguage = lsLang;
      this._translate.use(lsLang as string);
    } else {
      this.currentLanguage = this._translate.getDefaultLang();
    }

    this.getResolutionOffsetValue();
  }

  get offset() {
    const multiplier = this.languages.findIndex(lang => lang === this.currentLanguage);
    const offsetRem = 0.25 + multiplier * this.resolutionOffsetValue;
    return `${offsetRem}rem`;
  }

  async changeLanguage(language: LanguageConstants) {
    this._translate.use(language as string);
    this.currentLanguage = language;
    await this._localStorage.saveLanguage(language);

    this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeLanguage, this.currentLanguage));
  }

  private getResolutionOffsetValue() {
    if (window.innerWidth < 769) {
      this.resolutionOffsetValue = 2;
    } else {
      this.resolutionOffsetValue = 2.5;
    }
  }
}
