import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { firstValueFrom } from "rxjs";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { Network } from "src/app/common/enums/network.enum";
import { BitcoinRawTxDto } from "../common/DTO/bitcoin/bitcoin-raw-tx.dto";

@Injectable({
  providedIn: "root",
})
export class BitcoinService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getBitcoinTransactions(
    wallet: string,
    lastTxId: string | null = null
  ): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    const response = await this.getBitcoinTxs(wallet, lastTxId);
    if (!response) {
      return [];
    }

    for (const tx of response) {
      const dto = new BitcoinRawTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async estimateBitcoinTransferCost(): Promise<number> {
    try {
      const url = `${this._env.bitcoinApiUrl}/v1/fees/recommended`;
      const response = (await firstValueFrom(this._http.get(url))) as { fastestFee?: number };
      return response?.fastestFee ?? 0;
    } catch {
      return 0;
    }
  }

  private async getBitcoinTxs(wallet: string, lastTxId: string | null = null) {
    try {
      let url = `${this._env.bitcoinApiUrl}/address/${wallet}/txs/chain`;
      const urlSearchParams = new URLSearchParams();
      if (lastTxId) {
        urlSearchParams.append("txid", lastTxId);
      }
      const urlSearchParamsString = urlSearchParams.toString();
      if (urlSearchParamsString) {
        url += `?${urlSearchParamsString}`;
      }
      return (await firstValueFrom(this._http.get(url))) as BitcoinRawTxDto[];
    } catch {
      return null;
    }
  }

  private parseTx(tx: BitcoinRawTxDto, userWallet: string): Transaction {
    const txDto = new Transaction();

    const fromAddress = tx.vin?.[0]?.prevout?.scriptpubkey_address;
    const toAddress = tx.vout?.[0]?.scriptpubkey_address;

    txDto.from = fromAddress;
    txDto.to = toAddress;
    txDto.hash = tx.txid;
    txDto.createdAt = new Date((tx.status.block_time ?? 0) * 1000);
    txDto.timestamp = Number(0) * 1000;
    txDto.fee = tx.fee / 10 ** 8;
    txDto.id = tx.txid;
    txDto.type = fromAddress.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Bitcoin;
    txDto.amount = ((tx.vout?.[0]?.value ?? 0) / 10 ** 8).toString();
    txDto.status = tx.status.confirmed ? TxStatus.Approved : TxStatus.Canceled;
    txDto.token = CryptoToken.Bitcoin;
    txDto.isCommission = false;

    return txDto;
  }
}
