import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserDto } from "src/app/common/DTO/users/user.dto";

type Settings = "phone" | "password" | "pin" | "email" | "delete";
type SettingsTab = {
  id: Settings;
  title: string;
  icon: string;
};

@Component({
  selector: "app-user-settings-modal",
  templateUrl: "./user-settings-modal.component.html",
  styleUrls: ["./user-settings-modal.component.css"],
})
export class UserSettingsModalComponent {
  @Input() user: UserDto | null = null;

  public SETTINGS_TABS: SettingsTab[] = [
    { id: "phone", title: "Номер телефона", icon: "settings/phone" },
    { id: "password", title: "Пароль", icon: "settings/lock" },
    { id: "pin", title: "Пинкод", icon: "settings/pin" },
    { id: "email", title: "Email", icon: "settings/email" },
    { id: "delete", title: "Удалить аккаунт", icon: "settings/trash" },
  ];
  public currentTab = this.SETTINGS_TABS[0];

  constructor(private _modalRef: NgbActiveModal) {}

  public onClose() {
    this._modalRef.close();
  }

  public onTabSelect(tab: SettingsTab) {
    this.currentTab = tab;
  }
}
