<div class="relative rounded-full bg-gray-100/50 p-1 dark:bg-gray-900">
  <div
    class="absolute rounded-full bg-white/90 transition-all dark:bg-slate-400"
    [style.left]="offset"
    [ngClass]="{ 'top-1 h-8 w-11': size === 'md', 'top-1 h-7 w-8': size === 'sm' }"
  ></div>

  <div class="relative flex">
    <button
      class="font-bold text-gray-900 dark:text-white"
      [ngClass]="{ 'px-3 py-1': size === 'md', 'px-2 text-sm': size === 'sm' }"
      (click)="changeLanguage(LanguageConstants.RUSSIAN)"
    >
      Py
    </button>
    <button
      class="font-bold text-gray-900 dark:text-white"
      [ngClass]="{ 'px-3 py-1': size === 'md', 'px-2 py-1 text-sm': size === 'sm' }"
      (click)="changeLanguage(LanguageConstants.UZBEK)"
    >
      Uz
    </button>
  </div>
</div>
