<div class="block">
  <div
    *ngFor="let item of faqList; index as i"
    class="mb-4 cursor-pointer rounded-[1.875rem] bg-red-25/10 p-4 text-white last:mb-0 md:mb-5 md:px-15 md:py-13"
    (click)="toggleAccordion(i)"
  >
    <div class="flex flex-col gap-y-3 md:flex-row md:items-center md:gap-x-13 md:gap-y-0">
      <span class="w-[3.625rem] shrink-0 text-5xl font-bold opacity-50">
        {{ (i + 1).toString().padStart(2, "0") }}
      </span>

      <div class="flex grow items-center gap-x-4 md:gap-x-13">
        <span class="flex-1 text-left text-xl font-semibold">{{ item.question }}</span>
        <div
          class="ml-auto flex h-12 w-12 items-center justify-center rounded-full transition-all"
          [ngClass]="{ 'rotate-45 bg-black text-white': item.opened, 'bg-white text-black': !item.opened }"
        >
          <span class="mb-1 text-3xl leading-none">&plus;</span>
        </div>
      </div>
    </div>

    <p
      [@openClose]="item.opened"
      class="ml-[6.875rem] cursor-text overflow-hidden text-lg font-medium transition-all max-md:ml-0"
      [ngClass]="{ 'mt-0': !item.opened, 'mt-6': item.opened }"
      (click)="$event.stopPropagation()"
    >
      {{ item.answer }}
    </p>
  </div>
</div>
