<div class="relative mx-auto px-6 py-7 max-md:p-0 md:w-[31rem]">
  <ng-container [ngSwitch]="step">
    <div class="mb-6 max-md:flex max-md:items-center max-md:justify-between">
      <button
        (click)="onClose()"
        class="relative h-8 w-8 max-md:rounded-lg max-md:bg-red-50 dark:max-md:bg-slate-700 md:absolute md:-right-6 md:top-2"
        [ngClass]="{ hidden: step === 'success' || step === 'error', 'max-md:hidden': step === 'confirm' }"
      >
        <app-svg-icon name="close" class="max-md:hidden" />
        <app-svg-icon name="actions/back" class="w-4 text-slate-400 dark:text-white md:hidden" />
      </button>

      <ng-template ngSwitchCase="input">
        <div class="space-y-2 text-center">
          <h1 class="text-xl font-semibold">Обмен</h1>
          <p class="font-medium text-slate-400 dark:text-slate-200 max-md:hidden">Какие-то особенности</p>
        </div>
      </ng-template>
      <ng-template ngSwitchCase="confirm">
        <div class="space-y-2 text-center max-md:grow">
          <h1 class="text-xl font-semibold">Вы уверены что хотите обменять?</h1>
          <p class="font-medium text-slate-400 dark:text-slate-200">
            1 {{ fromCurrency === CryptoSymbol.Uzs ? toCurrencyName : fromCurrencyName }} =
            {{ oneUzsCurrencyAmount | mfeCustom }} UZS
          </p>
        </div>
      </ng-template>
      <ng-template ngSwitchCase="success">
        <h1 class="text-center text-xl font-semibold max-md:grow">Успешно</h1>
      </ng-template>
      <ng-template ngSwitchCase="error">
        <h1 class="text-center text-xl font-semibold max-md:grow">Ошибка</h1>
      </ng-template>

      <div class="block h-6 w-6 md:hidden" [ngClass]="{ 'max-md:hidden': step !== 'input' }">
        <!-- <app-svg-icon name="info" class="h-8 w-8" /> -->
      </div>
    </div>

    <!-- Input -->
    <ng-template ngSwitchCase="input">
      <form
        class="flex flex-col items-center gap-y-2 max-md:mt-3"
        [formGroup]="swapForm"
        (ngSubmit)="onInputSubmit()"
      >
        <div class="rounded-2xl bg-gray-25 p-4 dark:border dark:border-slate-700 dark:bg-slate-800">
          <div class="grid grid-cols-2 gap-x-4">
            <div>
              <span class="mb-1 block font-medium dark:text-slate-200">Что меняем</span>
              <app-currency-dropdown
                [items]="currencies"
                [isFullWidthList]="true"
                (onSelect)="selectFromCurrency($event)"
              >
                <div
                  class="flex items-center gap-x-2 rounded-2xl bg-white px-4 py-2 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
                >
                  <app-currency-icon [currency]="fromCurrency" size="xs" />
                  <span class="flex-1 py-2">{{ fromCurrencyName }}</span>
                  <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
                </div>
              </app-currency-dropdown>
            </div>

            <div>
              <app-input
                id="fromAmount"
                type="number"
                name="fromAmount"
                formControlName="fromAmount"
                title="Сумма"
                placeholder="100"
                appPositiveDecimals
                [maxLength]="fromCurrency === CryptoSymbol.Bitcoin ? 6 : 3"
                [errorMsg]="fromAmountError"
                (input)="calculateToAmount()"
              />
            </div>
          </div>

          <span
            *ngIf="fromWalletBalance !== null && fromCurrency !== CryptoSymbol.Uzs"
            class="mt-2 block text-sm font-medium text-slate-400 dark:text-slate-200"
          >
            Доступный баланс {{ fromWalletBalance.amount | mfeCustom }} {{ fromCurrencyName }}
          </span>
        </div>

        <app-svg-icon name="actions/swap" class="h-6 w-6 text-slate-400" />

        <div class="rounded-2xl bg-gray-25 p-4 dark:border dark:border-slate-700 dark:bg-slate-800">
          <div class="grid grid-cols-2 gap-x-4">
            <div>
              <span class="mb-1 block font-medium dark:text-slate-200">На что</span>
              <app-currency-dropdown
                [items]="currencies"
                [isFullWidthList]="true"
                (onSelect)="selectToCurrency($event)"
              >
                <div
                  class="flex items-center gap-x-2 rounded-2xl bg-white px-4 py-2 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
                >
                  <app-currency-icon [currency]="toCurrency" size="xs" />
                  <span class="flex-1 py-2">{{ toCurrencyName }}</span>
                  <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
                </div>
              </app-currency-dropdown>
            </div>

            <div>
              <app-input
                id="toAmount"
                type="number"
                name="toAmount"
                formControlName="toAmount"
                title="Сумма"
                placeholder="100"
                appPositiveDecimals
                [disabled]="swapForm.controls['toAmount'].disabled"
                [maxLength]="toCurrency === CryptoSymbol.Bitcoin ? 6 : 3"
                [errorMsg]="toAmountError"
              />
            </div>
          </div>

          <span class="mt-2 block text-sm font-medium text-slate-400 dark:text-slate-200">
            1 {{ fromCurrency === CryptoSymbol.Uzs ? toCurrencyName : fromCurrencyName }} =
            {{ oneUzsCurrencyAmount | mfeCustom }} UZS
          </span>
        </div>

        <button
          type="submit"
          class="btn-primary btn-medium mt-6 w-full"
          [disabled]="swapForm.invalid || fromAmountError || toAmountError"
        >
          {{ fromCurrency === CryptoSymbol.Uzs ? "Купить" : "Продать" }}
        </button>
      </form>
    </ng-template>

    <!-- Confirmation -->
    <ng-template ngSwitchCase="confirm">
      <div class="mx-auto flex items-center justify-center gap-2 max-md:flex-col">
        <div
          class="rounded-2xl bg-gray-25 p-4 text-lg font-medium dark:border dark:border-slate-700 dark:bg-transparent dark:text-slate-400"
        >
          {{ swapForm.controls["fromAmount"].value! | mfeCustom }}
          <span class="dark:text-slate-200">{{ fromCurrencyName }}</span>
        </div>
        <app-svg-icon
          name="actions/back"
          class="h-6 w-6 rotate-180 text-slate-400 dark:text-slate-200 max-md:-rotate-90"
        />
        <div
          class="rounded-2xl bg-gray-25 p-4 text-lg font-medium dark:border dark:border-slate-700 dark:bg-transparent dark:text-slate-400"
        >
          {{ swapForm.controls["toAmount"].value! | mfeCustom }}
          <span class="dark:text-slate-200">{{ toCurrencyName }}</span>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-x-4">
        <button class="btn-primary-dark btn-medium" (click)="backToInput()">Нет</button>
        <button class="btn-primary btn-medium" (click)="onCheckout()">
          Да ({{ confirmationTimeCounter }})
        </button>
      </div>
    </ng-template>

    <!-- Success -->
    <ng-template ngSwitchCase="success">
      <img src="assets/images/success-check-mark.png" alt="success" class="mx-auto w-[7.5rem]" />
      <div class="mt-6">
        <p class="px-6 dark:text-slate-200 max-md:p-0 max-md:text-center">Вы обменяли:</p>
        <div class="mx-auto mt-6 flex items-center justify-center gap-2 max-md:flex-col">
          <div
            class="rounded-2xl bg-gray-25 p-4 text-lg font-medium dark:border dark:border-slate-700 dark:bg-transparent dark:text-slate-400"
          >
            {{ swapForm.controls["fromAmount"].value! | mfeCustom }}
            <span class="dark:text-slate-200">{{ fromCurrencyName }}</span>
          </div>
          <app-svg-icon
            name="actions/back"
            class="h-6 w-6 rotate-180 text-slate-400 dark:text-slate-200 max-md:-rotate-90"
          />
          <div
            class="rounded-2xl bg-gray-25 p-4 text-lg font-medium dark:border dark:border-slate-700 dark:bg-transparent dark:text-slate-400"
          >
            {{ swapForm.controls["toAmount"].value! | mfeCustom }}
            <span class="dark:text-slate-200">{{ toCurrencyName }}</span>
          </div>
        </div>
      </div>
      <button class="btn-primary-dark btn-medium mt-10 w-full" (click)="onClose()">Закрыть</button>
    </ng-template>

    <!-- Error -->
    <ng-template ngSwitchCase="error">
      <div class="rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
        <img src="assets/images/error-check-mark.png" alt="error" class="mx-auto w-[7.5rem]" />
        <p class="mt-6 dark:text-slate-400">Не удалось обменять средства</p>
      </div>
      <button class="btn-primary-dark btn-medium mt-6 w-full" (click)="onClose()">Закрыть</button>
    </ng-template>
  </ng-container>
</div>
