<button (click)="onClose()" class="absolute right-2 top-2 max-md:right-0 max-md:top-0">
  <app-svg-icon name="actions/close" class="block h-8 w-8 text-black dark:text-white" />
</button>

<div class="flex flex-col gap-y-6 px-15 py-7 max-md:p-0">
  <h2 class="text-center text-xl font-semibold">{{ "Kyc.Verification" | translate }}</h2>
  <!-- <p class="text-xl">
    {{ "Kyc.Download_app" | translate }}
  </p>

  <div class="grid grid-cols-[6.25rem_6.25rem_1fr] items-center gap-x-5">
    <img src="assets/images/myid-logo-2.jpg" alt="MyId logo" />
    <img src="assets/images/arrow.png" alt="arrow" />
    <img src="assets/images/myid-qr-2.png" alt="MyId QR" />
  </div>

  <div class="grid grid-cols-2 gap-x-4">
    <a
      href="https://apps.apple.com/ru/app/myid-ozbekiston/id1562842955"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md border border-border-input-color px-10 py-3"
    >
      <img src="assets/images/app-store.png" alt="download on the app store" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=uz.uzinfocom.myid"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md border border-border-input-color px-10 py-3"
    >
      <img src="assets/images/play-market.png" alt="download on the play market" />
    </a>
  </div>

  <p class="text-xl">{{ "Kyc.After_downloading" | translate }}</p> -->

  <a *ngIf="!isMiniApp" [href]="myIdLink" target="_blank" class="btn-primary btn-medium">
    {{ "Kyc.Proceed_with_mobile_app" | translate }}</a
  >
  <button *ngIf="isMiniApp" class="btn-primary btn-medium" (click)="handleRedirectFromTelegramMiniApp()">
    {{ "Kyc.Proceed_with_mobile_app" | translate }}
  </button>

  <a routerLink="/new-my-id-verification" class="btn-primary btn-medium" (click)="onClose()">
    {{ "Kyc.Proceed_on_website" | translate }}</a
  >
</div>
