<div class="mx-auto flex w-[18.75rem] flex-col items-center justify-center space-y-6 py-7 max-md:p-0">
  <h4 class="text-2xl font-semibold">Удаление карты</h4>
  <p>Уверены, что хотите удалить карту?</p>

  <app-bank-card [card]="card" [index]="cardIndex" class="w-full" />

  <div class="grid w-full grid-cols-2 gap-x-4">
    <button class="btn-primary-danger btn-medium" (click)="onDeleteCard()">Да</button>
    <button class="btn-primary-dark btn-medium" (click)="closeModal()">Нет</button>
  </div>
</div>
