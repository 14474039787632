import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CardDto } from "src/app/common/DTO/cards/card.dto";

@Component({
  selector: "app-bank-card",
  templateUrl: "./bank-card.component.html",
  styleUrls: ["./bank-card.component.css"],
})
export class BankCardComponent {
  @Input() card: CardDto = new CardDto();
  @Input() withSelect = false;
  @Input() selected = false;
  @Input() index: number = 0;
  @Input() showActions = false;

  @Output() onSelect = new EventEmitter<CardDto>();
  @Output() onDelete = new EventEmitter<number>();

  showDetails = false;

  constructor() {}

  onClickSelect() {
    this.onSelect.emit(this.card);
  }

  onClickDelete() {
    this.onDelete.emit(this.card.id);
  }

  getBankType(): "uzcard" | "humo" {
    if (this.card.maskedPan.startsWith("9860")) {
      return "humo";
    }
    return "uzcard";
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
}
