<div class="grid w-max grid-flow-col gap-x-5 max-md:gap-x-3">
  <button
    *ngFor="let tab of translatedTabs; index as i"
    class="text-3xl font-semibold uppercase transition-colors hover:text-black dark:hover:text-white max-md:text-lg"
    [ngClass]="currentTabNumber == i ? 'text-black dark:text-white' : 'text-black/30 dark:text-white/30'"
    (click)="onTabSelect(i)"
    type="button"
  >
    {{ tab }}
  </button>
</div>
